import React from 'react'

import { Badge, Button, Card, CardBody, UncontrolledTooltip, CardFooter, CardTitle, NavItem, NavLink, Nav, TabContent, TabPane, Container, Row, Col } from 'reactstrap'

import TagsInput from 'react-tagsinput'
import { toast } from 'react-toastify'
import { getApiPath } from 'config.js'

import { controller } from 'controller.js'
import GalleryForm from 'components/Forms/GalleryForm.js'

class GalleryView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            is_public: true,
            allow_public_upload: false,
            title: '',
            header: '',
            description: '',
            tags: [],
        }

        this.gallery_edited = this.gallery_edited.bind(this)

        this.loadGallery = this.loadGallery.bind(this)
        this.loadGallery()
    }

    handleChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value,
        })
    }

    gallery_edited(e) {
        this.loadGallery()
    }

    loadGallery() {
        let headers = controller.get_headers()
        let api_url = getApiPath() + '/user/list/get_by_id/' + this.props.gallery
        fetch(api_url, headers)
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    controller.get('alert').setDanger('Error', data.error_msg)
                    return
                }

                if (data.list_type == 'favs') {
                    data.title = 'Favourite pictures'
                    data.description = ''
                    data.name = 'favs'
                }

                this.setState({
                    gallery: data,
                    name: data.name,
                    title: data.title,
                    header: data.header,
                    username: data.username,
                    description: data.description,
                    cover_id: data.cover_id,
                    background_id: data.background_id,
                    tags: data.tags,
                    view_gallery: '/user/' + data.username + '/' + data.name,
                })
            })
            .catch((error) => {
                debugger;
                controller.get('alert').setDanger('Error', 'There was a problem accessing this server')
                console.log(error)
            })
    }

    render() {
        // If the album, gallery doesn't have a default image cover, we just use a default one.
        var image_list = ['project18', 'project1', 'project20', 'project19']
        let image_random = require('assets/img/' + image_list[this.props.count % image_list.length] + '.jpg').default

        var bkg = null

        if (this.state.name) {
            bkg = !this.state.cover_id ? image_random : getApiPath() + `/media/get_image/` + this.state.cover_id
        }

        let deletedMask = this.state.is_deleted ? <div className="deleted_mask"></div> : ''

        let is_current_user = controller.get_current_username() == this.state.username
        let show_controls = is_current_user && !this.state.is_deleted

        return (
            <>
                <Col className="px-0 gallery-header" md="6">
                    <Card
                        className="card-raised card-background card-background-product card-no-shadow fade-in"
                        style={{
                            backgroundImage: 'url(' + bkg + ')',
                        }}
                    >
                        {deletedMask}
                        <div className="gallery_overlay text-white fade-in">
                            <div
                                className="gallery_overlay_ui"
                                onClick={(e) => {
                                    e.preventDefault()
                                    window.location.href = this.state.view_gallery
                                }}
                            >
                                {show_controls ? (
                                    <div className="div-bottom" style={{ paddingBottom: '5px' }}>
                                        <Button
                                            id="view_all_tooltip"
                                            className="btn btn-round "
                                            color="neutral"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                window.location.href = this.state.view_gallery
                                            }}
                                        >
                                            <i className="now-ui-icons ui-1_send"></i> View gallery
                                        </Button>
                                        <UncontrolledTooltip delay={500} placement="top" target="view_all_tooltip">
                                            Go to the gallery
                                        </UncontrolledTooltip>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {show_controls ? (
                                    <div>
                                        <i
                                            id="remove_delete_tooltip"
                                            className="text-shadow zoom fas fa-times fa-2x"
                                            style={{ color: 'white', marginTop: '10px', position: 'absolute', bottom: 5, right: 15 }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                controller.gallery_remove(
                                                    this.props.gallery,
                                                    () => {
                                                        this.state.is_deleted = true
                                                        this.forceUpdate()
                                                        toast.success('Deleted!')
                                                    },
                                                    (error) => {
                                                        toast.error('Failed deleting media ' + error)
                                                    }
                                                )
                                            }}
                                        ></i>

                                        <UncontrolledTooltip delay={500} placement="top" target="remove_delete_tooltip">
                                            Deletes this gallery, but doesn't delete the media on it.
                                        </UncontrolledTooltip>
                                    </div>
                                ) : (
                                    <div style={{ color: 'white', position: 'absolute', bottom: '2px', left: '5px', zIndex: 2000 }}>
                                        <div className="zoom">{ this.state.is_deleted ? "DELETED" : "" }</div>
                                    </div>
                                )}

                                {show_controls ? (
                                    <div className="">
                                        <i
                                            id="maket_it_edit"
                                            className="text-shadow zoom fal fa-edit fa-2x pull-left"
                                            style={{ color: 'white', position: 'absolute', bottom: '7px', left: '9px', zIndex: 2000 }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                let modal = controller.get('modal_large')
                                                modal.show(<GalleryForm gallery={this.state.gallery} callback={this.gallery_edited} />)
                                                modal.setModal(true)
                                            }}
                                        ></i>

                                        <UncontrolledTooltip delay={100} placement="top" target="maket_it_edit">
                                            Edit this gallery information
                                        </UncontrolledTooltip>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <CardBody>
                            <a href="#test">

                                <h3 className="category">{this.state.title == this.state.header ? "" : this.state.title}</h3>
                                {this.state.name == 'favs' ? (
                                    <p className="card-description">
                                        <i className="fal fa-heart fa-5x"></i>
                                    </p>
                                ) : (
                                    ''
                                )}
                                <CardTitle tag="h3">{this.state.header}</CardTitle>
                                <p className="card-description">{this.state.description}</p>
                            </a>
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    }
}

export default GalleryView
