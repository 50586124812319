import React from 'react'

import { Modal, ModalFooter, Button, Card, CardTitle, CardBody, CardFooter, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Col, Row } from 'reactstrap'

import { controller } from 'controller.js'

class ModalLarge extends React.Component {
    componentDidMount() {
        controller.register('modal_large', this)
        this.setModal = this.setModal.bind(this)
        this.show = this.show.bind(this)
    }

    constructor(props) {
        super(props)
        this.state = {}
        this.state.content = []
    }

    show(content) {
        this.setState({
            content: content,
        })
    }

    setModal(value) {
        console.log(' Set modal ' + value)
        if (value) {
        } else {
            this.setState({ content: [] })
        }

        this.setState({ modalNotice: value })
    }

    render() {
        return (
            <>
                <div className="hidden">
                    <Modal
                        isOpen={this.state.modalNotice}
                        toggle={() => {
                            this.setModal(false)
                        }}
                        style={{ zIndex: 10000, margin: '0 !important' }}
                    >
                        {React.Children.toArray(this.state.content)}
                        <div className="new_gallery_overlay_ui">
                            <button aria-hidden={false} className="close" onClick={() => this.setModal(false)} type="button">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </button>
                        </div>
                    </Modal>
                </div>
            </>
        )
    }
}
export default ModalLarge
