import React from 'react'

import { Button, Card, CardBody, Collapse, FormGroup, Form, Input, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col, Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'

import GalleryForm from 'components/Forms/GalleryForm.js'
import GalleryView from 'components/GalleryView.js'

import { controller } from 'controller.js'

class UserGalleriesNavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: props.username,
            all_galleries: false,
            galleries: [],
            show_gallery_form: false,
            show_galleries_view: props.show_galleries_view,
        }

        controller.get_user(this, props.username)
        controller.get_galleries(this, props.username)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show_galleries_view !== this.props.show_galleries_view) {
            // Force re-render
            this.state.show_galleries_view = this.props.show_galleries_view
        }
    }

    render() {
        if (!this.state.user) return 'Loading...'

        let api = controller.get_route_apicall(this.props.username)
        if (api.gallery_id == 'galleries') {
            this.state.all_galleries = true
        }

        var galleries = []

        if (this.state.all_galleries && this.state.galleries) {
            let count = 0

            // Convert to an array
            let lst = []
            for (var key in this.state.galleries) lst.push(this.state.galleries[key])

            // The array is form by mongo IDs which are basically timestamps that can be sorted
            lst.sort().reverse()
            for (var my_id of lst) galleries.unshift(<GalleryView gallery_list={this} gallery={my_id} count={count++} username={this.state.user ? this.state.user.username : ''} />)
        }

        var nav_galleries = []

        let count = 0
        let is_current_user = controller.is_current_user(this)

        if (window.location.pathname == "/me")
            this.state.no_galleries = false

        for (var key in this.state.galleries) {
            if (count > 5)
                break
            count += 1

            if (is_current_user && key == 'favs')
                continue

            let nav_gallery = (
                <NavItem className={''}>
                    <NavLink
                        my_gallery={key}
                        onClick={(e) => {
                            e.preventDefault()
                            this.setState({ show_gallery_form: false, all_galleries: false })

                            let new_gallery = e.target.getAttribute('my_gallery')
                            let new_url = '/user/' + this.state.username + '/' + new_gallery

                            const new_state = { username: this.state.username, gallery: key }
                            //window.history.pushState(new_state, 'Img-API ' + key, new_url)
                            window.location.href = new_url
                        }}
                    >
                        {key.replace('_', '_')}
                    </NavLink>
                </NavItem>
            )
            nav_galleries.push(nav_gallery)
        }

        return (
            <>
                <div className="cd-section" id="header_gallery">
                    <div className="header">
                        <Navbar className="navbar-black bg-info navbar-absolute" expand="lg">
                            <Container>
                                <Nav className="mx-auto" navbar>
                                    <NavItem className={this.state.all_galleries ? 'active' : ''}>
                                        <NavLink
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault()

                                                //this.setState({ show_gallery_form: false, all_galleries: !this.state.all_galleries })
                                                if (controller.is_current_user(this))
                                                    window.location.href = '/gallery/' + this.state.username
                                                else
                                                    window.location.href = '/user/' + this.state.username + '/galleries'
                                            }}
                                        >
                                            {controller.is_current_user(this) ? 'View Galleries' : 'View public Galleries'}
                                        </NavLink>
                                    </NavItem>
                                    {React.Children.toArray(nav_galleries)}

                                    {controller.is_current_user(this) ? (
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setState({ show_gallery_form: true, all_galleries: false })
                                                }}
                                            >
                                                <i className="fas fa-plus"></i> Create New Gallery
                                            </NavLink>
                                        </NavItem>
                                    ) : (
                                        ''
                                    )}
                                </Nav>
                            </Container>
                        </Navbar>

                        {this.state.show_gallery_form || this.state.no_galleries ? (
                            <div className="page-header header-filter">
                                <div
                                    className="page-header-image"
                                    style={{
                                        backgroundImage: 'url(' + require('assets/img/bg16.jpg').default + ')',
                                    }}
                                ></div>
                                <Container fluid style={{ paddingTop: '60px' }}>
                                    <Container>
                                        <GalleryForm />
                                    </Container>
                                </Container>
                            </div>
                        ) : (
                            <div>
                                <Container fluid style={{ paddingTop: '80px' }}>
                                    <Row>{React.Children.toArray(galleries)}</Row>
                                </Container>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export default UserGalleriesNavBar
