import React, { useEffect, useState } from 'react'
import { getApiPath } from 'config.js'
import { controller } from 'controller.js'

import MediaOverlay from 'components/MediaOverlay.js'
import { Container } from 'reactstrap'
import { toast } from 'react-toastify'
import EditableText from 'components/EditableText.js'

var margin_left = 7
var margin_bottom = 48

var max_height = 300
var d_ = false
var all_loaded = false

var enable_full_width = false
var MAX_HEIGHT_LIMIT = max_height

// We want to force the scroll to be loaded before we start doing our
document.body.style.overflowY = 'scroll'

var media_cache = {}

function isInViewport(element, extra_margin = 0) {
    // We check if the image is on viewport before attempting to load it, so we don't hammer the service displaying images outside the current view

    const rect = element.getBoundingClientRect()
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight

    if (rect.bottom < 0) {
        return false
    }

    let marginLoad = max_height * 2
    if (rect.top > viewportHeight + marginLoad + extra_margin) {
        return false
    }

    return true
}

function isVideoInViewport(element, extra_margin = 25) {
    // We want to stop videos as soon as the top of the video goes out of screen.
    // We don't want to be like linkedin, that has videos playing two screens after the scroll... :-/

    const rect = element.getBoundingClientRect()
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight

    if (rect.top + extra_margin < 0) {
        return false
    }

    if (rect.bottom - extra_margin > viewportHeight) {
        return false
    }

    return true
}

// We store which videos are we playing
var video_playback = {}

function display_video(video_id) {
    var element = document.getElementById('video_div_' + video_id)
    var media = document.getElementById('video_player_' + video_id)
    var icon = document.getElementById('play_icon_' + video_id)
    var image = document.getElementById('image_' + video_id)
    let spinner = document.getElementById('spinner_' + video_id)

    if (image.height) {
        media.width = image.width
        media.height = image.height
    }

    if (!hasClass(element, 'hidden')) {
        if (!media.controls) {
            addClass(element, 'hidden')
            icon.style.display = null
            media.pause()
            media.currentTime = 0

            delete video_playback[video_id]
            removeClass(image, 'hidden')
            removeClass(spinner, 'hidden')
        }
    } else {
        addClass(image, 'hidden')

        removeClass(element, 'hidden')
        icon.style.display = 'none'
        media.play()
        media.addEventListener('playing', (event) => {
            console.log('Video started playing')
            addClass(spinner, 'hidden')
        })

        media.addEventListener('stalled', (event) => {
            removeClass(spinner, 'hidden')
        })

        media.addEventListener('waiting', (event) => {
            removeClass(spinner, 'hidden')
        })

        video_playback[video_id] = element
    }
}

function stop_videos_out_of_view() {
    var play_copy = {}
    Object.assign(play_copy, video_playback)
    for (let k in play_copy) {
        let element = play_copy[k]
        if (!isVideoInViewport(element)) {
            display_video(k)
        }
    }
}

function setupVideo() {
    var click_users = document.getElementsByClassName('click_user')
    for (let user of click_users) {
        user.addEventListener('click', function (evt) {
            evt.preventDefault()
            var href = this.getAttribute('href')
            console.log(' Redirect to user ' + href + ' ' + this.id)
            window.location.href = href
        })
    }

    var gifs = document.getElementsByClassName('media_format_GIF')

    for (let gif of gifs) {
        gif.parentNode.addEventListener('mouseover', function (evt) {
            evt.preventDefault()
            let img = this.querySelector('.media_format_GIF')
            img.src = img.getAttribute('src_media')
            let icon_id = 'play_icon_' + img.getAttribute('media_id')
            addClass(document.getElementById(icon_id), 'hidden')
        })
    }
}

function findParentClass(element, class_search) {
    while (element && !hasClass(element, class_search)) {
        element = element.parentNode
    }

    return element
}

function hasClass(ele, cls) {
    //console.log(ele.className);
    return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

function addClass(ele, cls) {
    if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

function removeClass(ele, cls) {
    if (hasClass(ele, cls)) {
        var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
        ele.className = ele.className.replace(reg, ' ')
    }
}

function get_scaled_width(image, max_h = max_height) {
    let real_w = image.getAttribute('image_width')
    let real_h = image.getAttribute('image_height')
    let asp = real_w / real_h
    let w = max_h * asp
    return Math.floor(w)
}

let debounce_loading = 0

function adjust_stack(row, stack, current_w, max_width, total_count) {
    let asp = 0

    max_width -= margin_left * (stack.length - 1)
    asp = current_w / max_width

    let final_w = 0
    let final_h = 0

    let count = 0
    let disable_float_right = false

    for (let image of stack) {
        let image_w = Math.floor(get_scaled_width(image) / asp)
        let image_h = Math.floor(max_height / asp)

        if (d_) console.log('Real ' + count + ` ${image_w} , ${image_h} `)

        // Clamp to a maximum height, otherwise it will use all the available space

        if (!enable_full_width && image_h > MAX_HEIGHT_LIMIT) {
            image_h = MAX_HEIGHT_LIMIT
            image_w = Math.floor(get_scaled_width(image, image_h))
            disable_float_right = true
        }

        image.width = image_w
        image.height = image_h

        // Readjust video playback
        var media_id = image.getAttribute('media_id')

        var video = document.getElementById('video_player_' + media_id)
        if (video && image.height) {
            video.width = image_w
            video.height = image_h
        }

        var old_row = image.getAttribute('row')
        if (old_row == null) {
            image.setAttribute('row', row)
            /*
            var isLoaded = image.complete && image.naturalHeight !== 0
            if (isLoaded) {
                setTimeout(() => {
                    addClass(image, 'fade-in')
                }, total_count * 20)
            } else {
                image.onload = () => {
                    addClass(image, 'fade-in')
                }
            }
            */
        } else if (old_row != row) {
            if (isInViewport(image)) {
                let image_count = image.getAttribute('image_count')
                console.log(image_count + ':: INVALIDATE ' + row)
                image.setAttribute('row', row)

                image.classList.remove('blur_filter')
                void image.offsetWidth // Force the animation to restart
                void image.offsetHeight
                image.classList.add('blur_filter')
            }
        }

        final_w += image.width
        final_h = image.height

        count += 1

        let gallery = findParentClass(image, 'img_gallery')

        // We let the one on the right to float so we can adjust to the DIV to counter half pixel calculations
        if (!disable_float_right && stack.length > 1 && count == stack.length && count != 1) {
            removeClass(gallery, 'pull-left')
            addClass(gallery, 'pull-right')

            // We don't add margin on the right
            gallery.style['margin-left'] = ''
        } else {
            // We don't add margin on the left
            if (count == 1) gallery.style['margin-left'] = ''
            else {
                gallery.style['margin-left'] = margin_left + 'px'
                final_w += margin_left
            }

            removeClass(gallery, 'pull-right')
            addClass(gallery, 'pull-left')
        }
    }

    /*
    // After adjust
    for (let image of stack) {
        if (isInViewport(image)) {
            if (image.src != image.getAttribute('src_')) {
                image.src = image.getAttribute('src_')
                image.style.backgroundColor = '#1f1f2a'
                image.style.objectFit = 'contain' // contain
            }
        }
    }
    */

    if (d_) console.log(' Final Height ' + final_h)
    return final_h
}

function preload_images_viewport() {
    var images = document.getElementsByClassName('img-row')

    for (let image of images) {
        if (!isInViewport(image, 500)) continue

        let media = media_cache[image.getAttribute('media_id')]
        if (!media) {
            //console.log(' Failed loading pagination ')
            continue
        }

        if (media.pagination && !media.pagination.is_loaded) {
            //console.log('======================== LOAD PAGE ' + media.pagination.page + ' ========================')
            media.pagination.is_loaded = true
            controller.get('gallery').loadPage(media.pagination.page + 1)
            //console.log('DISPLAY ' + media.media_id + ' ' + media.file_name)
        }
    }
}

// Dynamic load
var timeout_load = 0

function load_images() {
    clearTimeout(timeout_load)
    timeout_load = setTimeout(() => {
        console.log(' SCROLL ')
        var images = document.getElementsByClassName('img-row')

        for (let image of images) {
            if (!isInViewport(image)) continue

            if (image.src != image.getAttribute('src_')) {
                image.src = image.getAttribute('src_')
                image.style.objectFit = 'fit'

                image.onload = function () {
                    if (this.src == this.getAttribute('src_')) {
                        this.style.filter = ''
                        this.classList.add('blur_filter')
                    }
                }

                image.classList.remove('fade-in')
                void image.offsetWidth // Force the animation to restart
                image.classList.add('fade-in')
            }
        }
    }, 250)
}

document.addEventListener('scroll', function (e) {
    // Skip fast scrolling by not doing the loading until the user stops

    stop_videos_out_of_view()
    preload_images_viewport()
    load_images()
})

function adjust_images_to_row() {
    var main_row = document.getElementById('main_row')
    if (!main_row) return

    if (d_) console.log('MAX WIDTH ' + main_row.clientWidth)

    let max_width = main_row.clientWidth
    var images = document.getElementsByClassName('img-row')

    if (images.length <= 1) {
        clearInterval(adjust_interval)
        return -1
    }

    let w = 0
    let stack = []

    let div_height = 0
    let row = 0
    for (let image of images) {
        let count = image.getAttribute('image_count')
        let image_w = get_scaled_width(image)

        w += image_w
        if (d_) console.log(' ' + image.getAttribute('image_count') + ` (${image_w} => ${w}) `)

        if (w > max_width) {
            if (d_) console.log('------------- Width overflow ' + count + ' ----------------')
            stack.push(image)
            div_height += adjust_stack(row, stack, w, max_width, row) + margin_bottom

            w = 0
            row += 1
            stack = []
            continue
        }

        stack.push(image)
    }

    div_height += adjust_stack(row, stack, w, max_width) + margin_bottom

    // Added extra margin to allocate for some weird space issues
    div_height += max_height

    main_row.style.maxHeight = div_height + 'px'
    main_row.style.height = div_height + 'px'

    if (all_loaded) {
        if (adjust_interval) {
            clearInterval(adjust_interval)
            adjust_interval = 0
            setTimeout(() => {
                // Finished adjusting the divs, we will have a different size for the div, since media might have change the sizes with a slight delay
                //console.log('Post transitions clear interval')
                adjust_images_to_row()
            }, 250)
        }
    }

    load_images()
    preload_images_viewport()
}

var adjust_interval = 0

window.addEventListener('load', function () {
    console.log('All assets are loaded')
    adjust_images_to_row()
})

window.addEventListener(
    'resize',
    function (event) {
        adjust_images_to_row()
    },
    true
)

var cache_api = {}

class TopImages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imageSource: [],
            api_call: null,
            gallery: null,
            is_empty: true,
            is_loaded: false,
            api_call: props.api_call,
            reverse_order: props.reverse_order,
            images: [],
        }

        enable_full_width = this.props.enable_full_width

        this.reload = this.reload.bind(this)
        this.loadNext = this.loadNext.bind(this)
        this.loadPrev = this.loadPrev.bind(this)

        this.loadPage = this.loadPage.bind(this)
        this.loadApiCall = this.loadApiCall.bind(this)
        this.render_images = this.render_images.bind(this)

        this.get_media_text = this.get_media_text.bind(this)
        this.get_video_html = this.get_video_html.bind(this)
        this.get_image_html = this.get_image_html.bind(this)

        this.render_individual_image = this.render_individual_image.bind(this)

        controller.register('gallery', this)
    }

    reload() {
        console.log('================== Reload API call =========================')
        this.api_call = null
        this.loadApiCall(this.state.extra_params)
    }

    loadApiCall(extra_params = '', page = 0) {
        //console.log('-------------------- API CALL LOAD --------------------------')
        let api_call = getApiPath()

        api_call += this.state.api_call ? this.state.api_call : '/media/category/NEW'
        api_call += extra_params

        if (page) {
            if (!extra_params) api_call += '?'
            else api_call += '&'

            api_call += 'page=' + page
        }

        if (this.api_call == api_call) {
            return
        }

        if (page == 0) this.state.imageSource = []

        this.state.extra_params = extra_params

        console.log('API CALL ' + this.state.api_call)
        this.api_call = api_call
        /*
        return controller.get_gallery_call(this, api_call, extra_params, () => {
            //console.log(' Adjust interval ' + data.media_files.length)
            adjust_images_to_row()
            setupVideo()
        })
        */

        return fetch(api_call, controller.get_headers())
            .then((response) => response.json())
            .then((data) => {
                if (page == 0) {
                    if (data.status != 'success') {
                        this.setState({
                            is_loaded: true,
                            imageSource: [],
                            is_empty: true,
                        })

                        controller.get('alert').setDanger('Error', data.error_msg)
                        return data
                    }

                    if (!data.media_files || data.media_files.length == 0) {
                        clearInterval(adjust_interval)
                        this.setState({
                            gallery: data,
                            is_loaded: true,
                            is_empty: true,
                        })
                        return
                    }
                }

                //for (let media of data.media_files) {
                //    if (d_) console.log(media)
                //}

                let fileList = data.media_files

                if (extra_params) {
                    const state = { extra: extra_params }
                    const url = new URL(window.location)

                    let arr = url.pathname.split('/')

                    let new_media_id = fileList[0].media_id
                    arr[4] = new_media_id
                    let new_url = arr.join('/')

                    console.log(' Replace media id ' + new_media_id)
                    window.history.pushState(state, 'Img-API', new_url)
                    this.state.api_call = '/media/posts/' + new_media_id + '/get'
                    this.state.extra_params = ''
                    this.reload()
                    return data
                }

                let last = null

                if (fileList.length > 0) {
                    last = fileList[fileList.length - 1]
                    console.log('****************************** ADD PAGINATION TO ' + last.media_id + ' ' + last.file_name)
                    last.pagination = { page: data.page, items_per_page: data.items, offset: data.offset }
                }

                if (page > 0) {
                    fileList = this.state.imageSource.concat(fileList)
                }

                this.setState({
                    is_loaded: true,
                    is_empty: false,
                    gallery: data,
                    imageSource: fileList,
                })

                this.render_images()

                adjust_interval = setInterval(() => {
                    //console.log(' Adjust interval ' + data.media_files.length)
                    adjust_images_to_row()
                    setupVideo()
                }, 50)

                return data
            })
            .catch((error) => {
                debugger
                toast.error(error)
                console.log(error)
                controller.get('alert').setDanger('Error', 'There was a problem accessing this server')
            })
    }

    loadPage(page) {
        this.loadApiCall('', page)
    }

    loadPrev() {
        this.loadApiCall('?prev=' + this.props.gallery_id)
    }

    loadNext() {
        this.loadApiCall('?next=' + this.props.gallery_id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.api_call !== this.props.api_call) {
            console.log(' API CALL CHANGED ' + this.props.api_call)

            this.state.api_call = this.props.api_call
            this.api_call = this.props.api_call
            this.loadApiCall()
        }
    }

    componentDidMount() {
        this.loadApiCall()
    }

    get_media_text(media) {
        let username = !media.is_anon ? media.username : ''

        let user_href = `/user/${media.username}/stream`

        if (controller.get_current_username() == media.username) {
            let is_hidden = (media.my_description && (!media.my_title || media.my_title.length == 0) && media.my_description.length > 16) ? "hidden": ""

            return (
                <div
                    className="text-white"
                    style={{
                        width: '100%',
                        height: margin_bottom + 'px',
                        position: 'absolute',
                        bottom: margin_left + 'px',
                        left: '0px',
                        zIndex: 1,
                        backgroundColor: '#151515',
                        fontFamily: 'Montserrat',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <div style={{ fontSize: '1.0em', lineHeight: '1.45em', minHeight: '16px' }} className={'title_' + media.media_id + " " + is_hidden}>
                        <EditableText api_entry={`/media/${media.media_id}/set/my_title`} content={media.my_title} default="&nbsp;" show_border={false} one_line_only={true} />
                    </div>
                    <div className={'description_' + media.media_id} style={{ fontSize: '1.0em', lineHeight: '1.0em'}} >
                        <EditableText api_entry={`/media/${media.media_id}/set/my_description`} content={media.my_description} default="&nbsp;" show_border={false} one_line_only={true} />
                    </div>

                    <div style={{ fontSize: '0.8em', position: 'absolute', bottom: '2px', right: '2px', zIndex: 150 }}>
                        <div className="zoom click_user fade-in" href={user_href}>
                            {this.props.show_username && username ? username : ''}
                        </div>
                    </div>
                </div>
            )
        }

        var title = ''

        if (media.my_gallery_id) {
            let url = `/user/` + media.username + `/` + media.my_gallery_id
            title = <a href={url}> {media.my_title}</a>
        } else {
            if (media.my_title) title = media.my_title
        }

        return (
            <div
                className="text-white"
                style={{
                    width: '100%',
                    height: margin_bottom + 'px',
                    position: 'absolute',
                    bottom: margin_left + 'px',
                    left: '0px',
                    zIndex: 1,
                    backgroundColor: '#151515',
                    fontFamily: 'Montserrat',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                <div style={{ fontSize: '1.0em', lineHeight: '1.45em', minHeight: '16px' }}>{title}</div>
                <div className={'description_' + media.media_id}>{media.my_description}</div>

                <div style={{ fontSize: '0.8em', position: 'absolute', bottom: '2px', right: '2px', zIndex: 150 }}>
                    <div className="zoom click_user fade-in" href={user_href}>
                        {this.props.show_username && username ? username : ''}
                    </div>
                </div>
            </div>
        )
    }

    get_media_icon(media) {
        let play_icon_id = 'play_icon_' + media.media_id

        if (media.file_format == '.GIF') {
            let src = require('assets/icons/gif_icon.png').default
            return (
                <div className="media_icon">
                    <img border="0" className="play_icon" id={play_icon_id} src={src} width="35" height="18" style={{ zIndex: '1000' }} />
                </div>
            )
        }

        if (media.file_type != 'video') return ''

        let src = require('assets/icons/play_icon.png').default
        return (
            <div className="media_icon">
                <img border="0" className="play_icon" id={play_icon_id} src={src} width="16" height="16" style={{ zIndex: '1000' }} />
            </div>
        )
    }

    get_video_html(media, show_video_controls = false, my_style = {}) {
        if (media.file_type != 'video') return ''

        media.is_video = true

        let mid = media.media_id

        let spinner_id = `spinner_${mid}`
        let video_id = `video_div_${mid}`
        let video_player_id = `video_player_${mid}`
        let video_media_poster = getApiPath() + `/media/get/${mid}.v512.JPG`

        let media_src = getApiPath() + '/media/get/' + mid

        let my_flex_style = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }

        let video_html = (
            <>
                <div
                    className="div_video hidden"
                    id={video_id}
                    style={my_flex_style}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        let media_id = e.target.getAttribute('media_id')
                        let media = media_cache[media_id]
                        if (media && media.file_type == 'video') {
                            display_video(media_id)
                            return
                        }
                    }}
                    onDoubleClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        let media_id = e.target.getAttribute('media_id')
                        let media = media_cache[media_id]
                        if (media.href) window.location.href = media.href
                    }}
                >
                    <video
                        style={my_style}
                        media_id={media.media_id}
                        controls={show_video_controls}
                        loop={true}
                        preload="none"
                        poster={video_media_poster}
                        id={video_player_id}
                        className="video_media_player"
                    >
                        <source src={media_src} type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <div className="text-white video_loading_icon" id={spinner_id}>
                        <i className="fal fa-2x fa-spinner my_spin"></i>&nbsp;
                    </div>
                </div>
            </>
        )

        return video_html
    }

    get_image_html(media, image_count, no_preload = false, show_video_controls = false, hide_media_overlay = false) {
        let mid = media.media_id

        let path = getApiPath() + `/media/get/` + mid
        if (!no_preload || media.file_type == 'video') path += `.512.JPG`

        let web_entry = `/user/${media.username}/posts/${mid}`

        if (this.props.gallery_id)
            web_entry = `/user/${media.username}/${this.props.gallery_id}/${mid}`

        media['href'] = web_entry

        if (this.props.gallery_id) web_entry += '/gallery/' + this.props.gallery_id

        let media_src = getApiPath() + '/media/get/' + mid
        let media_format = 'images img-row media_format_' + media.file_format.substring(1)

        if (no_preload) {
            show_video_controls = true
            media_format += ' fade-in'
        }

        let image_id = `image_${mid}`

        let media_overlay = !hide_media_overlay ? <MediaOverlay media={media} show_username={this.props.show_username} gallery_id={this.state.gallery ? this.state.gallery.id : 'stream'} /> : ''

        var mystyle = {
            color: 'white',
            marginBottom: margin_left + 'px',
            fontFamily: 'Arial',
            objectFit: 'fit',
            backgroundColor: '#1f1f2a',
            maxHeight: '2048',
            filter: 'blur(6px)',
        }

        var my_flex_style = { marginBottom: margin_bottom + 'px' }
        if (no_preload) {
            my_flex_style = {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
            }

            mystyle = {
                marginBottom: '58px',
                height: '70vh',
                objectFit: 'contain',
            }
        }

        let image_loader_src = no_preload ? path : media_src + '.v32.PNG' //require('assets/icons/loader_3.png').default
        let image_html = (
            <>
                <div>
                    {this.get_media_text(media)}
                    <div className="" style={my_flex_style}>
                        <div href={web_entry} video_id={media.media_id} className="media_relative">
                            {this.get_video_html(media, show_video_controls, { marginBottom: margin_left + 'px' })}

                            <img
                                image_count={image_count}
                                key={'image__' + mid}
                                id={image_id}
                                media_id={mid}
                                className={media_format}
                                src_={path}
                                style={mystyle}
                                src_media={media_src}
                                src={image_loader_src}
                                image_width={media.info.width}
                                image_height={media.info.height}
                                media_format={media.file_format}
                            />

                            <div
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    let media_id = e.target.getAttribute('media_id')
                                    let media = media_cache[media_id]

                                    if (media && media.file_type == 'video') {
                                        display_video(media_id)
                                        return
                                    }
                                }}
                                onDoubleClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    console.log(' Redirect to video ')

                                    let media_id = e.target.getAttribute('media_id')
                                    let media = media_cache[media_id]

                                    if (media.href) window.location.href = media.href
                                }}
                            >
                                {media_overlay}
                                {this.get_media_icon(media)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

        return image_html
    }

    render_individual_image() {
        var media = this.state.imageSource[0]
        media_cache[media.media_id] = media

        let ret = (
            <div key={'key_' + media.media_id} style={{ height: '100vh' }}>
                <div style={{ position: 'absolute', top: '50%', left: '10px', zIndex: 1500 }}>
                    <button
                        type="button"
                        className="btn-icon btn-round btn btn-info fade-in"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.loadPrev()
                        }}
                    >
                        <i className="fas fa-arrow-left"></i>
                    </button>
                </div>

                <div style={{ position: 'absolute', top: '50%', right: '10px', zIndex: 1500 }}>
                    <button
                        type="button"
                        className="btn-icon btn-round btn btn-info fade-in"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.loadNext()
                        }}
                    >
                        <i className="fas fa-arrow-right"></i>
                    </button>
                </div>
                <div className="media_relative">{this.get_image_html(media, 0, true)}</div>
            </div>
        )

        return ret
    }

    render_images() {
        let total_images = this.state.imageSource.length
        let show_video_controls = total_images == 1 ? true : false

        if (total_images == 1) {
            let ret = this.render_individual_image()
            this.setState({ images: [ret] })
            return
        }

        let images = []

        for (var i = 0; i < total_images; i++) {
            var media = this.state.imageSource[i]
            media_cache[media.media_id] = media

            //if (i > 100) continue

            //console.log(i + ':: ==== LOAD ' + media.file_name + ' =====')

            if (!media.info) {
                console.log('ERROR: No media info ')
                continue
            }

            let ret = (
                <div className={total_images == 1 ? 'img_gallery full-width' : 'img_gallery'} image_count={i} key={media.media_id}>
                    <div className="bg-image hover-overlay ripple media_relative">{this.get_image_html(media, i)}</div>
                </div>
            )

            images.push(ret)
        }

        all_loaded = true
        if (this.state.reverse_order) images.reverse()

        this.setState({ images: images })
    }

    render() {
        //console.log('================== Render Images ==================')
        if (!this.state.is_loaded) {
            return ' Not loaded yet '
        }

        if (this.state.is_empty) {
            console.log(' Gallery is empty ')
            return (
                <div className="full-center">
                    <br></br>
                    <h3 className="text-white">EMPTY GALLERY</h3>
                </div>
            )
        }

        // https://www.amitmerchant.com/auto-assigning-unique-key-to-each-child-of-list-in-react/
        var ret = (
            <div>
                <div
                    id="main_row"
                    className="page-header-image"
                    style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        backgroundColor: '#000',
                    }}
                >
                    {this.state.images}
                </div>
            </div>
        )

        return ret
    }
}

export default TopImages
