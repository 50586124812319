import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { toast } from 'react-toastify'

import MediaForm from 'components/Forms/MediaForm.js'

// styles
import 'assets/css/bootstrap.min.css'
import 'assets/scss/now-ui-kit.scss?v=1.5.0'
//import 'assets/demo/demo.css?v=1.5.0'
//import 'assets/demo/react-demo.css?v=1.5.0'
import 'assets/demo/nucleo-icons-page-styles.css?v=1.5.0'

import 'assets/vendor/fa-pro/css/fontawesome.css'
import 'assets/vendor/fa-pro/css/all.css'
import 'assets/vendor/fa-pro/css/brands.css'
import 'assets/vendor/fa-pro/css/solid.css'

// pages
import AboutUs from 'views/examples/AboutUs.js'
import PublicGalleriesPage from 'views/PublicGalleries.js'
import UserGallery from 'views/examples/UserGallery.js'
import BlogPost from 'views/examples/BlogPost.js'
import BlogPosts from 'views/examples/BlogPosts.js'
import ContactUs from 'views/examples/ContactUs.js'
import Ecommerce from 'views/examples/Ecommerce.js'
import Index from 'views/Index.js'
import LandingPage from 'views/examples/LandingPage.js'
import LoginPage from 'views/examples/LoginPage.js'
import NucleoIcons from 'views/NucleoIcons.js'
import Presentation from 'views/Presentation.js'
import Pricing from 'views/examples/Pricing.js'
import ProductPage from 'views/examples/ProductPage.js'
import ProfilePage from 'views/examples/ProfilePage.js'
import Sections from 'views/Sections.js'
import SignupPage from 'views/examples/SignupPage.js'
import LogoutPage from 'views/examples/LogoutPage.js'
import UserPage from 'views/examples/UserPage.js'

import ImgAPI from 'views/ImgAPI.js'
// others
import 'react-toastify/dist/ReactToastify.css'

import Dropzone from 'react-dropzone'
import Modals from 'components/Modals.js'
import ModalLarge from 'components/ModalLarge.js'

import { controller } from 'controller.js'

import ReactGA from 'react-ga'
const TRACKING_ID = 'UA-107499160-5'
ReactGA.initialize(TRACKING_ID)

function onDrop(acceptedFiles, rejectedFiles) {
    // After dropping a file, set the global dropzonestate to false
    console.log('Accepted files: ', acceptedFiles)
    console.log('Rejected files: ', rejectedFiles)

    for (let input of acceptedFiles) {
        toast.info('Uploading file ' + input.name)
    }

    controller.upload_files(
        acceptedFiles,
        (data) => {
            toast.success('Success!')
            var g = controller.get('gallery')
            if (g) g.reload()
        },
        (error) => {
            toast.error(error)
        }
    )
}

function onDragEnter() {
    console.log('Entered Drag Dropzone')
}

function onDragLeave() {
    console.log('Left Drag Dropzone')
}

const onChangeStatus = ({ meta }, status) => {
    console.log(status, meta)
}

var dropStyle = {
    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
}

var dropActiveStyle = {}

function renderMain() {
    let api = controller.get_route_apicall()


    ReactGA.pageview(window.location.pathname + window.location.search)

    return (
        <Dropzone
            ref={controller.get_dropzone_ref()}
            onDrop={onDrop}
            style={dropStyle}
            activeStyle={dropActiveStyle}
            className={''}
            activeClassName={''}
            disableClick={true}
            isDragAccept={true}
            noClick={true}
            noKeyboard={true}
            onChangeStatus={onChangeStatus}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            accept={{
                'video/mp4': ['.mp4'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/gif': ['.gif'],
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <div
                    {...getRootProps({
                        // Disable click to not pass it on to Dropzone
                        onClick: (event) => event.stopPropagation(),
                    })}
                >
                    <input {...getInputProps()} hidden />
                    <BrowserRouter>
                        <Switch>
                            <Route path="/me" render={(props) => <UserPage {...props} username={api.username} value="me" />} />
                            <Route path="/user" render={(props) => <UserPage {...props} username={api.username} value="user" />} />
                            <Route path="/favs" render={(props) => <UserPage {...props} username={api.username} value="favs" />} />
                            <Route path="/index" render={(props) => <Index {...props} username={api.username} />} />

                            <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
                            <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
                            <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
                            <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
                            <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} />
                            <Route path="/index" render={(props) => <Index {...props} />} />
                            <Route path="/landing-page" render={(props) => <LandingPage {...props} />} />
                            <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
                            <Route path="/login" render={(props) => <LoginPage {...props} />} />
                            <Route path="/nucleo-icons" render={(props) => <NucleoIcons {...props} />} />
                            <Route path="/presentation" render={(props) => <Presentation {...props} />} />
                            <Route path="/pricing" render={(props) => <Pricing {...props} />} />
                            <Route path="/product-page" render={(props) => <ProductPage {...props} />} />
                            <Route path="/profile-page" render={(props) => <ProfilePage {...props} />} />
                            <Route path="/sections" render={(props) => <Sections {...props} />} />
                            <Route path="/sign-up" render={(props) => <SignupPage {...props} />} />
                            <Route path="/logout" render={(props) => <LogoutPage {...props} />} />
                            <Route path="/gallery" render={(props) => <UserGallery {...props} />} />
                            <Route path="/public_galleries" render={(props) => <PublicGalleriesPage {...props} />} />

                            <Route path="/" render={(props) => <ImgAPI {...props} />} />
                            <Redirect to="/" />
                        </Switch>
                    </BrowserRouter>
                    <Modals />
                    <ModalLarge />

                    <div id="div_image_upload_canvas"></div>
                </div>
            )}
        </Dropzone>
    )
}

ReactDOM.render(renderMain(), document.getElementById('root'))

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
}

document.onpaste = function (event) {
    var items = (event.clipboardData || event.originalEvent.clipboardData).items
    for (let index in items) {
        var item = items[index]
        if (item.kind === 'file') {
            var blob = item.getAsFile()
            var reader = new FileReader()
            reader.onload = function (event) {
                //console.log(event.target.result)

                if (!blob.type.startsWith('image') && !blob.type.startsWith('video')) {
                    console.log('Backend will reject not supported ' + blob.type)
                    return
                }

                const container = document.getElementById('div_image_upload_canvas')
                var img = document.createElement('img')
                img.src = event.target.result
                let my_media = dataURLtoFile(event.target.result, blob.name)

                container.appendChild(img)

                controller.upload_files(
                    [my_media],
                    (data) => {
                        toast.success('Success!')
                    },
                    (error) => {
                        toast.error(error)
                    }
                )
            }
            reader.readAsDataURL(blob)
        }
    }
}
