/*eslint-disable*/
import React from 'react'

// reactstrap components
import { Container } from 'reactstrap'

// core components

function FooterBlack() {
    return (
        <>
            <footer className="footer" data-background-color="black">
                <Container>
                    <nav>
                        <ul>
                            <li>
                                <a href="https://www.engineer.blue" target="_blank">
                                    Engineer.blue
                                </a>
                            </li>
                            <li>
                                <a href="/about-us" target="_blank">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a href="/blog-posts" target="_blank">
                                    Blog
                                </a>
                            </li>
                            <li>
                                <a color="github" href="https://github.com/sergioamr/img-api">
                                    <i className="fab fa-github"></i> API Source Code
                                </a>

                            </li>
                        </ul>
                    </nav>
                    <div className="copyright" id="copyright">
                        © {new Date().getFullYear()}, Img-API All Rights reserved
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default FooterBlack
