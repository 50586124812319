import React from 'react'

// reactstrap components
import { UncontrolledTooltip } from 'reactstrap'

import TopImages from 'views/sections-sections/TopImages.js'

import { controller } from 'controller.js'
// core components

class UploadHeader extends React.Component {
    constructor(props) {
        super(props)
        controller.set_dropzone_target_gallery(this.props.gallery_id)
    }

    render() {
        return (
            <section className="">
                <header>
                    <div
                        className="p-5 text-center bg-image"
                        style={{
                            backgroundImage: 'url(' + require('assets/icons/upload_background.webp').default + ')',
                            height: this.props.small ? '75px' : '200px',
                            marginBottom: '7px',
                        }}
                    >
                        <div className="mask-header" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <div className="text-white">
                                    {!this.props.small ? (
                                        <div>
                                            <h1 className="mb-3">
                                                <center>
                                                    <a
                                                        className="sticky-logo"
                                                        href="/"
                                                        style={{
                                                            fontFamily: '"Montserrat Thin", "Montserrat"',
                                                        }}
                                                    >
                                                        <h1>
                                                            <span style={{ color: 'rgb(62, 193, 213)' }}>IMG</span>
                                                            <span style={{ color: 'white' }}>API</span>
                                                        </h1>
                                                    </a>
                                                </center>
                                            </h1>

                                            <h4 className="mb-3">Where your images come to life!</h4>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <a
                                        className="btn btn-outline-light"
                                        href="/media/upload"
                                        role="button"
                                        id="tooltip871723210"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            controller.open_uploading_dialog()
                                        }}
                                    >
                                        {this.props.target_gallery_id == '' || this.props.target_gallery_id == 'stream' ? 'Drop anywhere images or videos' : 'Drop anywhere to upload to this gallery'}
                                    </a>
                                    <UncontrolledTooltip delay={0} placement="top" target="tooltip871723210">
                                        Upload to {this.props.target_gallery_id ? this.props.target_gallery_id : 'my stream'}
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </section>
        )
    }
}

export default UploadHeader
