import { controller } from 'controller'
import React from 'react'
import { createRef } from 'react'
import { toast } from 'react-toastify'
import {
    UncontrolledTooltip,
    Tooltip,
    Modal,
    ModalFooter,
    Button,
    Card,
    CardTitle,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
} from 'reactstrap'

import GalleriesModal from 'components/GalleriesModal.js'
import GalleryForm from 'components/Forms/GalleryForm.js'
import MediaForm from 'components/Forms/MediaForm.js'

class MediaOverlay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            removed_message: '~',
            is_public: props.media.is_public,
            media: props.media,
        }
    }

    setModal(value) {
        console.log(' Set modal ' + value)
        this.setState({ modalNotice: value })

        let modals = controller.get('modals')

        let new_gallery_callback = (data) => {
            controller.get('modal_large').setModal(false)
            this.state.gals_modal_ref.current.reload()
            toast.success('Ready!')
        }

        new_gallery_callback = new_gallery_callback.bind(this)

        let btn = (
            <Button
                className="btn-round"
                color="info"
                onClick={() => {
                    let modal = controller.get('modal_large')
                    modal.show(<GalleryForm callback={new_gallery_callback} />)
                    modal.setModal(true)
                }}
            >
                Create new Gallery
            </Button>
        )
        if (value) {
            this.state.gals_modal_ref = createRef()
            modals.show('Add to a Gallery', <GalleriesModal ref={this.state.gals_modal_ref} media={this.state.media} />, btn)
        }

        modals.setModal(value)
    }

    render() {
        let text_card = (
            <Card className="card-no-shadow">
                <CardBody>
                    <h6 className="category">Basic Civitas</h6>
                    <CardTitle tag="h3">The Right Mistake</CardTitle>
                    <p className="card-description">Living in South Central L.A., Socrates Fortlow is a sixty-year-old ex-convict, still strong enough to kill men with...</p>
                </CardBody>
            </Card>
        )

        let style_btns = { textShadow: '2px 2px 0 #000', color: 'white', marginTop: '10px', opacity: 0.15 }

        let deletedMask = <div className="deleted_mask" style={{ marginBottom: '7px' }}></div>

        let g_id = this.props.gallery_id
        let show_remove_from_gallery = g_id && g_id != 'stream' ? 'div-top' : 'hidden'

        let is_current_user = controller.is_current_media_user(this)
        let show_controls = is_current_user && !this.state.is_deleted

        if (!show_controls)
            show_remove_from_gallery = "hidden"

        let media_id = this.state.media.media_id
        return (
            <>
                {this.state.is_deleted ? deletedMask : ''}
                <div className="media_overlay text-white fade-in" style={{ fontFamily: "'Montserrat Thin', 'Montserrat'" }}>
                    {this.state.media.favs ? <i className="zoom fas fa-star fa-2x pull-right" style={style_btns}></i> : ''}
                    {!this.state.is_public ? <i className="zoom fas fa-lock fa-2x pull-left" style={style_btns}></i> : ''}
                    <div className="media_overlay_ui" media_id={media_id}>
                        <div>
                            <i
                                id={'add_to_favs_tooltip' + media_id}
                                className="text-shadow zoom fal fa-star fa-2x pull-right"
                                style={{ color: 'white', marginTop: '10px' }}
                                onClick={(e) => {
                                    debugger;
                                    e.preventDefault()
                                    e.stopPropagation()
                                    controller.media_toggle(this.state.media, 'favs', () => {
                                        this.state.media.favs = !this.state.media.favs
                                        this.forceUpdate()
                                    })
                                }}
                            ></i>
                            <UncontrolledTooltip delay={500} placement="top" target={'add_to_favs_tooltip' + media_id}>
                                {!this.state.media.favs ? 'Add to Favs' : 'Remove from favs'}
                            </UncontrolledTooltip>
                        </div>

                        {show_controls ? (
                            <div>
                                <i
                                    id={'makeit_public' + media_id}
                                    className="text-shadow zoom fal fa-lock fa-2x pull-left"
                                    style={{ color: 'white', marginTop: '10px' }}
                                    onClick={(e) => {
                                        debugger;
                                        e.preventDefault()
                                        e.stopPropagation()
                                        controller.media_toggle(this.state.media, 'is_public', (data) => {
                                            this.setState({
                                                is_public: data.privacy_mode != 'private',
                                            })
                                        })
                                    }}
                                ></i>

                                <UncontrolledTooltip delay={100} placement="top" target={'makeit_public' + this.state.media.media_id}>
                                    {!this.state.is_public ? 'Careful, this will make it public!' : 'Make it PRIVATE'}
                                </UncontrolledTooltip>
                            </div>
                        ) : (
                            ''
                        )}

                        <div className={show_remove_from_gallery}>
                            <Button
                                id={'set_as_cover_from_gallery' + media_id}
                                className="btn btn-round"
                                color="neutral"
                                onClick={(e) => {
                                    debugger;
                                    e.preventDefault()
                                    e.stopPropagation()
                                    controller.media_set_cover(this.state.media, this.props.gallery_id, () => {
                                        this.forceUpdate()
                                        toast.success('Success')
                                    })
                                }}
                            >
                                <i className="now-ui-icons design_image"></i> Set Cover
                            </Button>

                            <Button
                                id={'set_as_background_from_gallery' + media_id}
                                className="btn btn-round"
                                color="neutral"
                                onClick={(e) => {
                                    debugger;
                                    e.preventDefault()
                                    e.stopPropagation()
                                    controller.media_set_background(this.state.media, this.props.gallery_id, () => {
                                        this.forceUpdate()
                                        toast.success('Success')
                                    })
                                }}
                            >
                                <i className="now-ui-icons design_image"></i> Set Background
                            </Button>

                            <Button
                                id={'remove_from_gallery' + media_id}
                                className="btn btn-round"
                                color="neutral"
                                onClick={(e) => {
                                    debugger;
                                    e.preventDefault()
                                    e.stopPropagation()
                                    controller.media_detach(this.state.media, this.props.gallery_id, () => {
                                        this.state.is_deleted = true
                                        this.state.removed_message = 'REMOVED FROM GALLERY'
                                        this.forceUpdate()
                                        toast.success('Removed from gallery')
                                    })
                                }}
                            >
                                <i className="now-ui-icons ui-1_send"></i> Remove from Gallery
                            </Button>
                            <UncontrolledTooltip delay={500} placement="top" target={'remove_from_gallery' + media_id}>
                                Doesn't delete the media from your photostream.
                            </UncontrolledTooltip>
                        </div>

                        {show_controls ? (
                            <div className="div-bottom-icon">
                                <i
                                    id={'edit_this_media_file' + media_id}
                                    className="text-shadow zoom fal fa-edit fa-2x pull-left"
                                    style={{ color: 'white', marginTop: '10px' }}
                                    onClick={(e) => {
                                        debugger;
                                        e.preventDefault()
                                        e.stopPropagation()

                                        let new_media_update = (data) => {
                                            debugger
                                            controller.get('modal_large').setModal(false)
                                            let titles = document.getElementsByClassName('title_' + data.media_id)
                                            for (let title of titles) {
                                                title.innerHTML = data.my_title
                                            }

                                            let descriptions = document.getElementsByClassName('description_' + data.media_id)
                                            for (let desc of descriptions) {
                                                desc.innerHTML = data.my_description
                                            }

                                            this.state.media = data
                                            toast.success('Success')
                                        }

                                        let modal = controller.get('modal_large')
                                        modal.show(<MediaForm callback={new_media_update} media={this.state.media} />)
                                        modal.setModal(true)

                                        //window.location.href = '/media/edit/' + media_id
                                    }}
                                ></i>

                                <UncontrolledTooltip delay={100} placement="top" target={'edit_this_media_file' + media_id}>
                                    Edit this media
                                </UncontrolledTooltip>
                            </div>
                        ) : (
                            ''
                        )}

                        {show_controls ? (
                            <div>
                                <i
                                    id={'delete_media_file' + media_id}
                                    className="text-shadow zoom fas fa-times fa-2x"
                                    style={{ color: 'white', marginTop: '10px', position: 'absolute', bottom: 0, right: 5 }}
                                    onClick={(e) => {
                                        debugger;
                                        e.preventDefault()
                                        e.stopPropagation()
                                        controller.media_remove(
                                            this.state.media,
                                            () => {
                                                this.state.is_deleted = true
                                                this.state.removed_message = 'DELETED MEDIA'
                                                this.forceUpdate()
                                                toast.success('Deleted!')
                                            },
                                            () => {
                                                toast.error('Failed deleting media')
                                            }
                                        )
                                    }}
                                ></i>
                                <UncontrolledTooltip delay={500} placement="top" target={'delete_media_file' + media_id}>
                                    Delete the media forever
                                </UncontrolledTooltip>
                            </div>
                        ) : (
                            ''
                        )}

                        {show_controls ? (
                            <div>
                                <UncontrolledTooltip delay={500} placement="top" target={'add_to_gallery' + this.state.media.media_id}>
                                    Append to a new gallery
                                </UncontrolledTooltip>

                                <i
                                    id={'add_to_gallery' + this.state.media.media_id}
                                    className="zoom fas fa-add fa-2x text-shadow increase_click_area"
                                    style={{ color: 'white', marginTop: '0px', position: 'absolute', bottom: 0, left: 0 }}
                                    onClick={(e) => {
                                        debugger;
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.setModal(true)
                                    }}
                                ></i>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    {this.state.is_deleted ? (
                        <div style={{ coilor: 'red', position: 'absolute', bottom: '2px', left: '5px', zIndex: 2000 }}>
                            <div className="zoom">{this.state.removed_message}</div>
                        </div>
                    ) : (
                        ''
                    )}

                </div>
                {this.props.show_text ? text_card : ''}
            </>
        )
    }
}

export default MediaOverlay
