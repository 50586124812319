import React from 'react'

import {
    Card,
    CardHeader,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from 'reactstrap'

import TagsInput from 'react-tagsinput'
import { toast } from 'react-toastify'
import { getApiPath } from 'config.js'

import { controller } from 'controller.js'
// core components

class MediaForm extends React.Component {
    constructor(props) {
        super(props)

        if (props.media) {
            // Copy the gallery object, we don't want to change the parent
            this.state = Object.assign(
                {
                    callback: props.callback,
                    tags: [],
                },
                props.media
            )
        } else
            this.state = {
                media: props.media,
                callback: props.callback,
                title: '',
                description: '',
                is_public: true,
                is_unlisted: false,
                is_NSFW: false,
                tags: [],
            }

        this.submitForm = this.submitForm.bind(this)
    }

    handleChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value,
        })
    }

    submitForm(e) {
        e.preventDefault()
        const data = {
            media_id: this.state.media_id,
            tags: this.state.tags,
            is_public: this.state.is_public,
            is_unlisted: this.state.is_unlisted,

            is_cover: this.state.is_cover,
            is_profile: this.state.is_profile,
            is_NSFW: this.state.is_NSFW,
            my_title: this.state.my_title,
            my_description: this.state.my_description,
            my_source_url: this.state.my_source_url,
        }

        controller.media_update(this.state.media_id, data, this.state.callback)
    }

    render() {
        let awesomeFocus = false
        let nucleoFocus = false

        let filename = this.state.file_name ? this.state.file_name : ''

        return (
            <>
                <Row>
                    <Col className="ml-auto mr-auto" md="12" xl="12">
                        <Card>
                            <CardHeader>
                                <div id="inputs" className="white-background">
                                    <h3>
                                        {'Edit Media'} <small>{filename}</small>
                                    </h3>
                                    <br></br>
                                    <Row>
                                        <Col lg="12" sm="12">
                                            <FormGroup>
                                                <Input
                                                    name="my_title"
                                                    placeholder="Title"
                                                    type="text"
                                                    value={this.state.my_title ? this.state.my_title : ''}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                ></Input>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="12" sm="12">
                                            <br></br>
                                            <FormGroup>
                                                <Input
                                                    name="my_source_url"
                                                    placeholder="Source of origin or URL"
                                                    type="text"
                                                    value={this.state.my_source_url ? this.state.my_source_url : ''}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                ></Input>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="12" sm="12">
                                            <div className="title">
                                                <h4>Description</h4>
                                            </div>
                                            <Input
                                                rows="4"
                                                cols="80"
                                                name="my_description"
                                                type="textarea"
                                                value={this.state.my_description ? this.state.my_description : ''}
                                                placeholder="Write your description here..."
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col md="12">
                                            <div className="title">
                                                <h4>Tags</h4>
                                            </div>
                                            <TagsInput
                                                className="pull-left"
                                                tagProps={{
                                                    className: 'react-tagsinput-tag badge badge-success',
                                                }}
                                                onChange={(value) => this.setState({ tags: value })}
                                                value={this.state.tags}
                                                onlyUnique
                                            ></TagsInput>
                                        </Col>
                                        <Col md="12">
                                            <hr></hr>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.is_cover}
                                                        onChange={(e) => {
                                                            if (this.state.is_cover) {
                                                                this.setState({ is_cover: false })
                                                                console.log(' Cover Removed ')
                                                            } else {
                                                                console.log(' Cover set ')
                                                                this.setState({ is_cover: true })
                                                            }
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    {'Set as cover picture'}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.is_profile}
                                                        onChange={(e) => {
                                                            if (this.state.is_profile) {
                                                                this.setState({ is_profile: false })
                                                                console.log(' Profile Removed ')
                                                            } else {
                                                                console.log(' Profile set ')
                                                                this.setState({ is_profile: true })
                                                            }
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    {'Set as profile picture'}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <hr></hr>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.is_public}
                                                        onChange={(e) => {
                                                            this.state.is_public = !this.state.is_public
                                                            this.setState({
                                                                is_public: this.state.is_public,
                                                            })
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    {this.state.is_public ? 'Visible to everyone' : 'Visible to everyone'}
                                                </Label>
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.is_unlisted}
                                                        onChange={(e) => {
                                                            this.state.is_unlisted = !this.state.is_unlisted
                                                            this.setState({
                                                                is_unlisted: this.state.is_unlisted,
                                                            })
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    {this.state.is_unlisted ? 'Unlisted' : 'Unlisted'}
                                                </Label>
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.is_NSFW}
                                                        onChange={(e) => {
                                                            this.state.is_NSFW = !this.state.is_NSFW
                                                            this.setState({
                                                                is_NSFW: this.state.is_NSFW,
                                                            })
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    {this.state.is_NSFW ? 'NSFW': 'Not Suitable For Work'}
                                                </Label>
                                            </FormGroup>
                                        </Col>

                                        <Col md="12">
                                            {' '}
                                            <br></br>
                                        </Col>

                                        <Col md="12 div-bottom">
                                            <div className="title"></div>
                                            <Button className="btn-round" color="info" href="#" onClick={this.submitForm} size="lg">
                                                {'Save Media '}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default MediaForm
