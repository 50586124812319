import React from 'react'
import { getApiPath } from 'config.js'

import { Badge, Button, Card, CardHeader, CardBody, CardFooter, CardTitle, Container, Row, Col } from 'reactstrap'

import UserGalleriesNavBar from 'components/Navbars/UserGalleriesNavBar.js'

import { controller } from 'controller.js'

class UserHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            api_call: null,
            gallery_id: null,
            gallery_image: require('assets/img/demo_image_a-james-donovan-180375.jpg').default,
        }

        controller.get_user(this, props.username)

        if (props.gallery_id && props.gallery_id != 'posts') {
            controller.get_gallery(this, props.username, props.gallery_id)
        } else {
            this.state.gallery = {
                name: 'stream',
                title: 'Photostream',
            }
        }
    }

    render() {
        if (!this.state.user) return 'Loading...'
        if (!this.state.gallery) return 'Loading Gallery...'
        
        let gal = this.state.gallery

        if (!gal.cover_id) {
            if (!gal) controller.gallery_get_image('random', this, 'cover_id', 'favs')
            else if (gal.id) controller.gallery_get_image('cover', this, 'cover_id', gal.id)
        } else {
            console.log(' Set new image ')
        }

        let image = gal.cover_id ? getApiPath() + `/media/get_image/` + gal.cover_id : this.state.gallery_image

        let bckg = {
            height: '100%',
            objectFit: 'cover',
            backgroundImage: 'url(' + image + ')',
        }

        let background_image = !gal.background_id ? require('assets/img/bg26.jpg').default : getApiPath() + `/media/get_image/` + gal.background_id
        let background = {
            backgroundImage: 'url(' + background_image + ')',
        }

        return (
            <>
                <Container
                    fluid
                    style={{
                        fontFamily: 'Montserrat Thin, Montserrat',
                    }}
                >
                    <Row>
                        <Col className="px-0" md="6">
                            <Card className="card-user-fashion card-background" style={background}>
                                <CardBody>
                                    <CardTitle className="text-left" tag="div">
                                        <h2>
                                            <a href={'/portfolio/' + this.state.user.username} onClick={(e) => e.preventDefault()}>
                                                {this.state.user.first_name} {this.state.user.last_name}
                                            </a>
                                        </h2>
                                    </CardTitle>

                                    <CardFooter className="text-left">
                                        <div className="stats">
                                            <span>
                                                <i className="now-ui-icons users_circle-08"></i>
                                                <a href={'/user/' + this.state.user.username + '/stream'}>
                                                    Author <b>{this.state.user.username}</b>
                                                </a>
                                            </span>
                                            <span>
                                                <i className="now-ui-icons tech_watch-time"></i>
                                                Joined&nbsp;
                                                {this.state.user.creation_date ? new Date(this.state.user.creation_date * 1000).toLocaleDateString() : ''}
                                            </span>
                                        </div>
                                        <div className="stats-link pull-right">
                                            <a className="footer-link" href="#test" onClick={(e) => e.preventDefault()}>
                                                {/* People */}
                                            </a>
                                        </div>
                                    </CardFooter>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="px-0" md="6">
                            <div className="card-container">
                                <Card className="card-user-fashion">
                                    <CardTitle tag="h4">
                                        <a
                                            href="#test"
                                            className="text-white"
                                            onClick={(e) => (window.location.href = '/user/' + this.state.user.username + '/' + this.state.gallery.name)}
                                            style={{
                                                fontFamily: 'Montserrat Thin, Montserrat',
                                            }}
                                        >
                                            <span>{this.props.gallery_id == 'favs' ? 'My favourite pictures' : ''}</span>
                                            <span>{!this.props.gallery_id || this.props.gallery_id == 'stream' ? 'Photostream' : ''}</span>

                                            <span>
                                                <h5>{this.props.gallery_id && this.state.gallery ? this.state.gallery.title : ''}</h5>
                                            </span>
                                        </a>
                                    </CardTitle>
                                    <CardBody>
                                        <CardFooter>
                                            <div className="stats pull-right">
                                                <span>
                                                    {/*
                                                    <i className="now-ui-icons tech_watch-time"></i>
                                                     June 2, 2021
                                                */}
                                                </span>
                                            </div>
                                        </CardFooter>
                                    </CardBody>
                                </Card>

                                <Card className="card-user-fashion card-background fade-in" style={bckg}></Card>
                                <Container className="card-user-fashion gallery-text div-center">
                                    <div className="">
                                        <h4 className="text-white ">{this.state.gallery ? this.state.gallery.header : ''}</h4>
                                        <span className="text-white">{this.state.gallery ? this.state.gallery.description : ''}</span>
                                    </div>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default UserHeader

/*
<img src={this.state.gallery_image} className="fade-in" style={background_image}></img>
*/
