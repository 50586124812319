import React from 'react'
import { NavLink as RRNavLink } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { Button, Collapse, DropdownToggle, NavLink, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, Container, UncontrolledTooltip } from 'reactstrap'

import { controller } from 'controller.js'

class UserNavDropDown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
        }

        controller.get_user(this)
    }

    render() {
        if (!this.state.isLoaded) return ''

        if (!this.state.user) return ''

        var arr = window.location.pathname.split('/')
        let photostream = arr[1] == 'me' ? 'active' : ''
        let favs = (arr.length>= 3 && arr[3] == 'favs') ? 'active' : ''
        let galleries = (arr[1] == 'gallery') ? 'active' : ''

        return (
            <>
                <NavItem className={photostream}>
                    <NavLink tag={RRNavLink} to="/me">
                        Your PhotoStream{' '}
                    </NavLink>
                </NavItem>

                <NavItem className={favs}>
                    <NavLink tag={RRNavLink} to={'/user/' + this.state.user.username + '/favs'}>
                        <i className="now-ui-icons ui-2_favourite-28"></i> Favs
                    </NavLink>
                </NavItem>

                <NavItem className={galleries}>
                    <NavLink tag={RRNavLink} to={'/gallery/' + this.state.user.username} className="nav-link">
                        <i className="now-ui-icons design_palette"></i> Galleries
                    </NavLink>
                </NavItem>
                <NavLink></NavLink>
            </>
        )

        if (this.props.use_dropdown)
            return (
                <>
                    <NavLink
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()

                            this.setState({ show_gallery_form: false, all_galleries: true })
                        }}
                    >
                        All Galleries
                    </NavLink>
                    <UncontrolledDropdown nav>
                        <DropdownToggle caret color="default" data-toggle="dropdown" href="#sergio" id="navbarDropdownMenuLink1" nav onClick={(e) => e.preventDefault()}>
                            <i className="now-ui-icons sport_user-run"></i>
                            <p>You</p>
                        </DropdownToggle>
                        <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                            <DropdownItem to="/me" tag={Link}>
                                <i className="now-ui-icons design_image"></i>
                                photostream
                            </DropdownItem>
                            <DropdownItem to={'/user/' + this.state.user.username + '/favs'} tag={Link}>
                                <i className="now-ui-icons ui-2_favourite-28"></i>
                                Favourites
                            </DropdownItem>
                            <DropdownItem to={'/gallery/' + this.state.user.username} tag={Link}>
                                <i className="now-ui-icons design_palette"></i>
                                Galleries
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </>
            )
    }
}
export default UserNavDropDown
