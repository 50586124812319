import React from 'react'
import { createRef } from 'react'

import GalleryModalItem from 'components/GalleryModalItem.js'
import { controller } from 'controller.js'

class GalleriesModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            refs: {},
            gallery_items: [],
        }

        this.reload = this.reload.bind(this)
        controller.get_galleries(this)
    }

    reload() {
        debugger
        this.state.galleries = []
        controller.get_galleries(this)
    }

    render() {
        if (!this.state.galleries) return ''

        let count = Object.keys(this.state.galleries).length
        if (count == 0) {
            this.get('alert').setDanger('Error', 'No galleries for this user')
            return ''
        }

        for (const [key, value] of Object.entries(this.state.galleries)) {
            if (!this.state.refs[value]) {
                let new_ref = createRef()
                this.state.refs[value] = new_ref
                this.state.gallery_items.push(<GalleryModalItem ref={new_ref} gallery_id={value} media={this.props.media} />)
            } else {
                console.log(value + ' Already loaded ')
            }
        }

        return <>{React.Children.toArray(this.state.gallery_items)}</>
    }
}

export default GalleriesModal
