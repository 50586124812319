import React from 'react'

import { Button, Card, CardBody, Collapse, FormGroup, Form, Input, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col, Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'

import GalleryForm from 'components/Forms/GalleryForm.js'
import GalleryView from 'components/GalleryView.js'

import { controller } from 'controller.js'

class GalleriesList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: props.username,
            all_galleries: true,
            galleries: null,
            show_gallery_form: false,
        }

        controller.get_user(this, props.username)

        if (props.api_call) {
            controller.get_galleries_call(this, props.api_call)
        } else {
            controller.get_galleries(this, props.username)
        }
    }

    render() {
        if (this.props.allow_anon == undefined && !this.state.user) return 'Loading...'

        var galleries = []
        let count = 0
        let lst = []

        if (!this.state.galleries) return 'Loading galleries...'

        if (this.state.galleries) {
            if (Array.isArray(this.state.galleries)) {
                for (var gal of this.state.galleries) galleries.unshift(<GalleryView gallery_list={this} gallery={gal.id} count={count++} username={gal.username} />)
            } else {
                // Convert to an array

                for (var key in this.state.galleries) lst.push(this.state.galleries[key])

                // The array is form by mongo IDs which are basically timestamps that can be sorted
                lst.sort().reverse()
                for (var my_id of lst) galleries.unshift(<GalleryView gallery_list={this} gallery={my_id} count={count++} username={this.state.user ? this.state.user.username : ''} />)
            }
        }

        var username = ''
        if (this.state.user) username = <NavbarBrand href={'/user/' + this.state.user ? this.state.user.username : '' + '/stream'}>{this.state.user.username}</NavbarBrand>

        return (
            <>
                <div className="cd-section" id="headers">
                    <div className="header-1">
                        <Navbar className="navbar-transparent bg-info navbar-absolute" expand="lg">
                            <Container>
                                <div className="navbar-translate">{username}</div>
                                <Nav className="mx-auto" navbar>
                                    <NavItem className={this.state.all_galleries ? 'active' : ''}>
                                        <NavLink
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault()

                                                this.setState({ show_gallery_form: false, all_galleries: true })
                                            }}
                                        >
                                            All Galleries
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({ show_gallery_form: true, all_galleries: false })
                                            }}
                                        >
                                            <i className="fas fa-plus"></i> Create New Gallery
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Container>
                        </Navbar>

                        {this.state.show_gallery_form || this.state.no_galleries ? (
                            <div className="page-header header-filter">
                                <div
                                    className="page-header-image"
                                    style={{
                                        backgroundImage: 'url(' + require('assets/img/bg16.jpg').default + ')',
                                    }}
                                ></div>
                                <Container fluid style={{ paddingTop: '60px' }}>
                                    <Container>
                                        <GalleryForm />
                                    </Container>
                                </Container>
                            </div>
                        ) : (
                            <div>
                                <Container fluid style={{ paddingTop: '80px' }}>
                                    <Row>{React.Children.toArray(galleries)}</Row>
                                </Container>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

/*
backgroundImage: this.state.show_gallery_form || this.state.no_galleries ? 'url(' + require('assets/img/bg16.jpg').default + ')' : '',
*/

export default GalleriesList
