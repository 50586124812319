/*eslint-disable*/
import React from 'react'
import { Link } from 'react-router-dom'
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    Container,
    UncontrolledTooltip,
} from 'reactstrap'

import GetNavBar from "components/Navbars/GetNavBar.js";
import Notifications from 'components/Notifications.js'

function FixedTransparentNavbar() {
    const [collapseOpen, setCollapseOpen] = React.useState(false)

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle('nav-open')
                        setCollapseOpen(false)
                    }}
                />
            ) : null}
            <Navbar className="navbar-black" expand="lg">
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand to="/" tag={Link} id="navbar-brand">
                            <span style={{ fontSize:32 }}><span style={{ color:"#3ec1d5" }}>IMG</span>API</span>
                        </NavbarBrand>
                        <UncontrolledTooltip target="navbar-brand">Where your life is Transparent!</UncontrolledTooltip>
                        <button
                            onClick={() => {
                                document.documentElement.classList.toggle('nav-open')
                                setCollapseOpen(!collapseOpen)
                            }}
                            aria-expanded={collapseOpen}
                            className="navbar-toggler"
                        >
                            <span className="navbar-toggler-bar top-bar"></span>
                            <span className="navbar-toggler-bar middle-bar"></span>
                            <span className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                    </div>
                    <Collapse isOpen={collapseOpen} navbar>
                    <GetNavBar />
                    </Collapse>
                </Container>
            </Navbar>
            <Notifications />
        </>
    )
}

export default FixedTransparentNavbar
