import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, Container, UncontrolledTooltip } from 'reactstrap'

import { controller } from 'controller.js'

class UserNavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
        }

        controller.get_user(this)
    }

    render() {
        if (!this.state.user) {
            return (
                <>
                    <NavItem>
                        <Link to="/login-page">
                            <Button color="info">
                                <p>Login!</p>
                            </Button>
                        </Link>
                    </NavItem>
                    <NavItem>
                    <Link to="/sign-up">
                        <Button color="primary">
                            <p>Sign Up</p>
                        </Button>
                        </Link>
                    </NavItem>
                </>
            )
        }

        let logout_button = ''

        // smartinez logs a lot!
        if (this.state.user && this.state.user.username == 'smartinez') {
            logout_button = (
                <NavItem>
                    <Button className="nav-link" color="primary" href="/logout">
                        <p>Logout</p>
                    </Button>
                </NavItem>
            )
        }

        return (
            <>
                <UncontrolledDropdown nav>
                    <DropdownToggle caret color="default" data-toggle="dropdown" href="#pablo" id="navbarDropdownMenuLink_3" nav onClick={(e) => e.preventDefault()}>
                        <i aria-hidden={true} className="now-ui-icons sport_trophy"></i>
                        <p>{this.state.user.username}</p>
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink_3" right>
                        <DropdownItem tag={Link} to="/profile-page">
                            <i className="now-ui-icons users_single-02"></i>
                            Profile Page
                        </DropdownItem>

                        <DropdownItem tag={Link} to="/Logout">
                            <i className="now-ui-icons ui-1_settings-gear-63"></i>
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                {logout_button}
            </>
        )
    }
}
export default UserNavBar
