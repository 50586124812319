import React from 'react'
import ReactDOM from 'react-dom'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from 'sanitize-html'
import './editable_text.css'
import { controller } from 'controller.js'

class EditableText extends React.Component {
    sanitizeConf = {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'h1'],
        allowedAttributes: { a: ['href'] },
    }

    constructor(props) {
        super(props)

        if (props.default) this.edit_msg = props.default
        else this.edit_msg = '=== Click here to edit ==='

        this.state = {
            html: props.content ? props.content : this.edit_msg,
            editable: true,
            tag: props.tag ? props.tag : 'div',
        }

        if (!props.one_line_only) {
            this.sanitizeConf.allowedTags.push('br', 'hr', 'p')
        }

        if (props.show_border) {
            this.state.border = {
                border: '1px dashed #aaa',
            }

            // Flicker the Boxes so users know they can edit this
            setTimeout(() => {
                this.setState({
                    border: {},
                })
            }, 2000)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            this.setState({ html: this.props.content })
        }
    }

    handleChange = (evt) => {
        // Don't let extra characters not allowed in
        evt.target.value = sanitizeHtml(evt.target.value, this.sanitizeConf)

        this.setState({ html: evt.target.value })

        if (this.timeout) clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            if (this.props.api_entry) {
                console.log(this.props.api_entry + ' SAVE ' + evt.target.value)
                controller.save_update(this.props.api_entry, evt.target.value)
                return
            }

            console.log(this.props.section + ' SAVE ')
            controller.save_section_update(this.props.section, this.props.my_key, evt.target.value)
        }, 1000)
    }

    sanitize = () => {
        this.setState({ html: sanitizeHtml(this.state.html, this.sanitizeConf), border: {} })
    }

    toggleEditable = () => {
        this.setState({ editable: !this.state.editable, border: { border: '1px dashed #aaa' } })
    }

    onFocus = () => {
        if (this.state.html == this.edit_msg) {
            this.setState({ border: { border: '1px dashed #aaa' }, html: '' })
        } else this.setState({ border: { border: '1px dashed #aaa' } })
    }

    render = () => {
        const CustomTag = this.state.tag

        return (
            <CustomTag style={this.state.border}>
                <ContentEditable
                    className="editable description"
                    tagName="span"
                    html={this.state.html} // innerHTML of the editable div
                    disabled={!this.state.editable} // use true to disable edition
                    onFocus={this.onFocus}
                    onChange={this.handleChange} // handle innerHTML change
                    onBlur={this.sanitize}
                />
            </CustomTag>
        )

        let test = (
            <div>
                <h3>source</h3>
                <textarea className="editable" value={this.state.html} onChange={this.handleChange} onBlur={this.sanitize} />
                <h3>actions</h3>
                <EditButton cmd="italic" />
                <EditButton cmd="bold" />
                <EditButton cmd="formatBlock" arg="h1" name="heading" />
                <button onClick={this.toggleEditable}>Make {this.state.editable ? 'readonly' : 'editable'}</button>
            </div>
        )
    }
}

function EditButton(props) {
    return (
        <button
            key={props.cmd}
            onMouseDown={(evt) => {
                evt.preventDefault() // Avoids loosing focus from the editable area
                document.execCommand(props.cmd, false, props.arg) // Send the command to the browser
            }}
        >
            {props.name || props.cmd}
        </button>
    )
}

export default EditableText
