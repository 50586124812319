import React from 'react'
import { Link } from 'react-router-dom'
// reactstrap components
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, Container, UncontrolledTooltip } from 'reactstrap'

import Notifications from 'components/Notifications.js'
import GetNavBar from 'components/Navbars/GetNavBar.js'

function ScrollTransparentNavbar() {
    const [collapseOpen, setCollapseOpen] = React.useState(false)
    const [navbarColor, setNavbarColor] = React.useState((document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499 ? '' : ' navbar-transparent')
    const [buyButtonColor, setBuyButtonColor] = React.useState((document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499 ? 'info' : 'neutral')
    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (document.documentElement.scrollTop > 499 || document.body.scrollTop > 499) {
                setNavbarColor('')
                setBuyButtonColor('info')
            } else if (document.documentElement.scrollTop < 500 || document.body.scrollTop < 500) {
                setNavbarColor(' navbar-transparent')
                setBuyButtonColor('neutral')
            }
        }
        window.addEventListener('scroll', updateNavbarColor)
        return function cleanup() {
            window.removeEventListener('scroll', updateNavbarColor)
        }
    })

    if (false)
        return (
            <>
                {collapseOpen ? (
                    <div
                        id="bodyClick"
                        onClick={() => {
                            document.documentElement.classList.toggle('nav-open')
                            setCollapseOpen(false)
                        }}
                    />
                ) : null}

                <Navbar className={'fixed-top' + navbarColor} color="white" expand="lg">
                    <Container>
                        <div className="navbar-translate">
                            <NavbarBrand to="/" tag={Link} id="navbar-brand">
                                IMG-API Scroll transparent
                            </NavbarBrand>
                            <UncontrolledTooltip target="navbar-brand">Where your life is!</UncontrolledTooltip>
                            <button
                                onClick={() => {
                                    document.documentElement.classList.toggle('nav-open')
                                    setCollapseOpen(!collapseOpen)
                                }}
                                aria-expanded={collapseOpen}
                                className="navbar-toggler"
                            >
                                <span className="navbar-toggler-bar top-bar"></span>
                                <span className="navbar-toggler-bar middle-bar"></span>
                                <span className="navbar-toggler-bar bottom-bar"></span>
                            </button>
                        </div>
                        <Collapse isOpen={collapseOpen} navbar>
                            <Nav className="ml-auto" id="ceva" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle caret color="default" data-toggle="dropdown" href="#pablo" id="navbarDropdownMenuLink1" nav onClick={(e) => e.preventDefault()}>
                                        <i className="now-ui-icons design_app"></i>
                                        <p>Components</p>
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                                        <DropdownItem to="/" tag={Link}>
                                            <i className="now-ui-icons design_image"></i>
                                            Presentation
                                        </DropdownItem>
                                        <DropdownItem to="/index" tag={Link}>
                                            <i className="now-ui-icons business_chart-pie-36"></i>
                                            All components
                                        </DropdownItem>
                                        <DropdownItem
                                            href="https://demos.creative-tim.com/now-ui-kit-pro-react/#/documentation/introduction?ref=nuk-pro-react-scroll-transparent-navbar"
                                            target="_blank"
                                        >
                                            <i className="now-ui-icons design_bullet-list-67"></i>
                                            Documentation
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle caret color="default" data-toggle="dropdown" href="#pablo" id="navbarDropdownMenuLink" nav onClick={(e) => e.preventDefault()}>
                                        <i aria-hidden={true} className="now-ui-icons files_paper"></i>
                                        <p>Sections</p>
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                                        <DropdownItem to="/sections#headers" tag={Link}>
                                            <i className="now-ui-icons shopping_box"></i>
                                            Headers
                                        </DropdownItem>
                                        <DropdownItem to="/sections#features" tag={Link}>
                                            <i className="now-ui-icons ui-2_settings-90"></i>
                                            Features
                                        </DropdownItem>
                                        <DropdownItem to="/sections#blogs" tag={Link}>
                                            <i className="now-ui-icons text_align-left"></i>
                                            Blogs
                                        </DropdownItem>
                                        <DropdownItem to="/sections#teams" tag={Link}>
                                            <i className="now-ui-icons sport_user-run"></i>
                                            Teams
                                        </DropdownItem>
                                        <DropdownItem to="/sections#projects" tag={Link}>
                                            <i className="now-ui-icons education_paper"></i>
                                            Projects
                                        </DropdownItem>
                                        <DropdownItem to="/sections#pricing" tag={Link}>
                                            <i className="now-ui-icons business_money-coins"></i>
                                            Pricing
                                        </DropdownItem>
                                        <DropdownItem to="/sections#testimonials" tag={Link}>
                                            <i className="now-ui-icons ui-2_chat-round"></i>
                                            Testimonials
                                        </DropdownItem>
                                        <DropdownItem to="/sections#contact-us" tag={Link}>
                                            <i className="now-ui-icons tech_mobile"></i>
                                            Contact Us
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle caret color="default" data-toggle="dropdown" href="#pablo" id="navbarDropdownMenuLink" nav onClick={(e) => e.preventDefault()}>
                                        <i aria-hidden={true} className="now-ui-icons design_image"></i>
                                        <p>Examples</p>
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                                        <DropdownItem tag={Link} to="/about-us">
                                            <i className="now-ui-icons business_bulb-63"></i>
                                            About-us
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/blog-post">
                                            <i className="now-ui-icons text_align-left"></i>
                                            Blog Post
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/blog-posts">
                                            <i className="now-ui-icons design_bullet-list-67"></i>
                                            Blog Posts
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/contact-us">
                                            <i className="now-ui-icons location_pin"></i>
                                            Contact Us
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/landing-page">
                                            <i className="now-ui-icons education_paper"></i>
                                            Landing Page
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/login-page">
                                            <i className="now-ui-icons users_circle-08"></i>
                                            Login Page
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/pricing">
                                            <i className="now-ui-icons business_money-coins"></i>
                                            Pricing
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/e-commerce">
                                            <i className="now-ui-icons shopping_shop"></i>
                                            Ecommerce Page
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/product-page">
                                            <i className="now-ui-icons shopping_bag-16"></i>
                                            Product Page
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/profile-page">
                                            <i className="now-ui-icons users_single-02"></i>
                                            Profile Page
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/sign-up">
                                            <i className="now-ui-icons tech_mobile"></i>
                                            Signup Page
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                    <Button
                                        className="nav-link btn-default"
                                        color={buyButtonColor}
                                        href="https://www.creative-tim.com/product/now-ui-kit-pro-react?ref=nuk-pro-react-scroll-transparent-navbar"
                                        target="_blank"
                                    >
                                        <p>Buy Now</p>
                                    </Button>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
                <Notifications />
            </>
        )
    else {
        return (
            <>
                {collapseOpen ? (
                    <div
                        id="bodyClick"
                        onClick={() => {
                            document.documentElement.classList.toggle('nav-open')
                            setCollapseOpen(false)
                        }}
                    />
                ) : null}

                <Navbar className={'fixed-top' + navbarColor} color="white" expand="lg">
                    <Container>
                        <div className="navbar-translate">
                            <NavbarBrand to="/" tag={Link} id="navbar-brand">
                                <span style={{ fontSize:32 }}><span style={{ color:"#3ec1d5" }}>IMG</span>API</span>
                            </NavbarBrand>
                            <UncontrolledTooltip target="navbar-brand">Where your life is!</UncontrolledTooltip>
                            <button
                                onClick={() => {
                                    document.documentElement.classList.toggle('nav-open')
                                    setCollapseOpen(!collapseOpen)
                                }}
                                aria-expanded={collapseOpen}
                                className="navbar-toggler"
                            >
                                <span className="navbar-toggler-bar top-bar"></span>
                                <span className="navbar-toggler-bar middle-bar"></span>
                                <span className="navbar-toggler-bar bottom-bar"></span>
                            </button>
                        </div>
                        <Collapse isOpen={collapseOpen} navbar>
                            <GetNavBar />
                        </Collapse>
                    </Container>
                </Navbar>
                <Notifications />
            </>
        )
    }
}

export default ScrollTransparentNavbar
