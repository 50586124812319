import React from 'react'

import { Container } from 'reactstrap'
import { getApiPath } from 'config.js'

import EditableText from 'components/EditableText.js'

class ProfilePageHeader extends React.Component {
    constructor(props) {
        super(props)

        let pic = props.user.profile_mid ? getApiPath() + '/media/get/' + props.user.profile_mid + '.v256.JPG' : require('assets/icons/profile_pic.jpg').default
        this.state = {
            user: props.user,
            profile_pic: pic,
        }
    }

    render() {
        if (!this.state.user) return "<i class='fas fa-spinner my_spinner'></i> Loading... "

        return (
            <>
                <div
                    className="page-header clear-filter page-header-small"
                    filter-color="black"
                    style={{
                        filter: 'sepia(40%)',
                    }}
                >
                    <div
                        className="page-header-image"
                        style={{
                            filter: 'brightness(20%)',
                            backgroundImage: 'url(' + require('assets/img/bg19.jpg').default + ')',
                        }}
                    ></div>
                    <Container>
                        <div className="photo-container">
                            <img alt="..." src={this.state.profile_pic}></img>
                        </div>
                        <h3 className="title">
                            <EditableText api_entry="/user/set/first_name" content={this.state.user.first_name} show_border={true} tag="span" />
                            <EditableText api_entry="/user/set/last_name" content={this.state.user.last_name} show_border={true} tag="span"  />
                        </h3>
                        <p className="category">{this.state.user.category}</p>
                        <div className="content">
                            <div className="social-description">
                                <h2>{this.state.user.no_comments}</h2>
                                <p>Comments</p>
                            </div>
                            <div className="social-description">
                                <h2>{this.state.user.no_images}</h2>
                                <p>Images</p>
                            </div>
                            <div className="social-description">
                                <h2>{this.state.user.no_categories}</h2>
                                <p>Categories</p>
                            </div>
                        </div>
                        <hr></hr>
                    </Container>
                </div>
            </>
        )
    }
}

export default ProfilePageHeader
