import React from 'react'

import { Button, Card, CardBody, CardFooter, CardTitle, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from 'reactstrap'
import { getApiPath } from 'config.js'
import { toast } from 'react-toastify'

class SignUpForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            first_nameError: false,
            last_nameError: false,
            usernameError: false,
            emailError: false,
            passwordError: false,
            isDisabled: true,
        }
        this.submitForm = this.submitForm.bind(this)
    }

    validateUsername(username) {
        if (username === '' || username === null) return false

        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)/g
        let result = false

        if (username.length < 5) result = false
        else result = pattern.test(username)

        if (result === true) {
            this.setState({
                usernameError: false,
                username: username,
            })
        } else {
            this.setState({
                usernameError: true,
            })
        }
    }

    validateEmail(email) {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g
        const result = pattern.test(email)
        if (result === true) {
            this.setState({
                emailError: false,
                email: email,
            })
        } else {
            this.setState({
                emailError: true,
            })
        }
    }

    handleChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value,
        })

        if (e.target.name === 'username') {
            this.validateUsername(e.target.value)
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    first_nameError: true,
                })
            } else {
                this.setState({
                    first_nameError: false,
                    first_name: e.target.value,
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    last_nameError: true,
                })
            } else {
                this.setState({
                    last_nameError: false,
                    last_name: e.target.value,
                })
            }
        }

        if (e.target.name === 'email') {
            this.validateEmail(e.target.value)
        }

        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    passwordError: true,
                })
            } else {
                this.setState({
                    passwordError: false,
                    password: e.target.value,
                })
            }
        }

        if (
            this.state.usernameError === false &&
            this.state.first_nameError === false &&
            this.state.last_nameError === false &&
            this.state.emailError === false &&
            this.state.passwordError === false
        ) {
            this.setState({
                isDisabled: false,
            })
        }
    }

    submitForm(e) {
        e.preventDefault()
        const data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
        }

        fetch(getApiPath() + '/user/create', {
            method: 'post',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    alert(data.error_msg)
                    return
                }

                window.location.href = '/login-page?email=' + this.state.email
            })
            .catch((error) => {
                alert('INTERNAL SERVER ERROR ' + error)
            })
    }

    render() {
        const social = (
            <>
                <div className="social text-center">
                    <Button className="btn-icon btn-round mr-2" color="twitter">
                        <i className="fab fa-twitter"></i>
                    </Button>
                    <Button className="btn-icon btn-round mr-2" color="dribbble">
                        <i className="fab fa-dribbble"></i>
                    </Button>
                    <Button className="btn-icon btn-round" color="facebook">
                        <i className="fab fa-facebook"></i>
                    </Button>
                    <h5 className="card-description">or be classical</h5>
                </div>
            </>
        )
        return (
            <>
                <Card className="card-signup">
                    <CardBody>
                        <CardTitle className="text-center" tag="h4">
                            Register
                        </CardTitle>
                        <Form action="" className="form" method="">
                            <InputGroup className="">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons users_circle-08"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="username"
                                    name="username"
                                    placeholder="Username..."
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                ></Input>
                            </InputGroup>
                            {this.state.usernameError ? <span style={{ color: 'red' }}>Please enter a valid Username</span> : ''}

                            <InputGroup className="">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons users_single-02"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="first_name"
                                    name="first_name"
                                    placeholder="First Name..."
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                ></Input>
                            </InputGroup>
                            {this.state.first_nameError ? <span style={{ color: 'red' }}>Please enter some value</span> : ''}

                            <InputGroup className="">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons text_caps-small"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="last_name"
                                    name="last_name"
                                    placeholder="Last Name..."
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                ></Input>
                            </InputGroup>
                            {this.state.last_nameError ? <span style={{ color: 'red' }}>Please enter some value</span> : ''}

                            <InputGroup className="">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons ui-1_email-85"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="email"
                                    name="email"
                                    placeholder="Your Email..."
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                ></Input>
                            </InputGroup>
                            {this.state.emailError ? <span style={{ color: 'red' }}>Please enter some value</span> : ''}

                            <InputGroup className="">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons ui-1_email-85"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="password"
                                    name="password"
                                    placeholder="A long difficult password..."
                                    type="password"
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                ></Input>
                            </InputGroup>
                            {this.state.passwordError ? <span style={{ color: 'red' }}>Please enter some value</span> : ''}

                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}
                                    ></Input>
                                    <span className="form-check-sign"></span>I agree to the terms and{' '}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        conditions
                                    </a>
                                </Label>
                            </FormGroup>

                            <CardFooter className="text-center">
                                <Button className="btn-round" color="info" href="#pablo" onClick={this.submitForm} size="lg">
                                    Get Started
                                </Button>
                            </CardFooter>
                        </Form>
                    </CardBody>
                </Card>
            </>
        )
    }
}
export default SignUpForm
