import React from 'react'

import {
    Card,
    CardHeader,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from 'reactstrap'

import TagsInput from 'react-tagsinput'
import { toast } from 'react-toastify'
import { getApiPath } from 'config.js'

import { controller } from 'controller.js'
// core components

class GalleryForm extends React.Component {
    constructor(props) {
        super(props)

        if (props.gallery) {
            // Copy the gallery object, we don't want to change the parent
            this.state = Object.assign({}, props.gallery)
        } else
            this.state = {
                callback: props.callback,

                is_public: true,
                is_NSFW: false,
                is_unlisted: false,
                allow_public_upload: false,
                title: '',
                header: '',
                description: '',
                tags: [],
            }

        if (!this.state.callback) {
            this.state.callback = (data) => {
                setTimeout(() => {
                    window.location.href = '/user/' + data.username + '/' + data.galleries[0].name
                }, 1200)
                toast.success('Ready!')
            }
        }

        this.submitForm = this.submitForm.bind(this)
    }

    handleChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value,
        })
    }

    submitForm(e) {
        e.preventDefault()
        const data = {
            id: this.state.id,
            title: this.state.title,
            header: this.state.header,
            description: this.state.description,
            is_public: this.state.is_public,
            is_unlisted: this.state.is_unlisted,
            allow_public_upload: this.state.allow_public_upload,
            tags: this.state.tags,
        }

        let headers = controller.get_headers()
        headers.method = 'post'
        headers.body = JSON.stringify(data)

        let api_url = getApiPath() + '/user/list'
        api_url += !this.state.id ? '/create' : '/update'

        fetch(api_url, headers)
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    controller.get('alert').setDanger('Error', data.error_msg)
                    return
                }

                this.state.callback(data)
            })
            .catch((error) => {
                debugger;
                controller.get('alert').setDanger('Error', 'There was a problem accessing this server')
                console.log(error)
            })
    }

    render() {
        let awesomeFocus = false
        let nucleoFocus = false

        return (
            <>
                <Row>
                    <Col className="ml-auto mr-auto" md="12" xl="12">
                        <Card>
                            <CardHeader>
                                <div id="inputs" className="white-background">
                                    <h3>{!this.state.id ? 'New Gallery' : 'Edit Gallery ' + this.state.name}</h3>
                                    <br></br>
                                    <Row>
                                        <Col lg="3" sm="6">
                                            <FormGroup>
                                                <Input
                                                    name="title"
                                                    placeholder="Title"
                                                    type="text"
                                                    value={this.state.title}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                ></Input>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="9" sm="6">
                                            <FormGroup>
                                                <Input
                                                    name="header"
                                                    className=""
                                                    placeholder="Header"
                                                    type="text"
                                                    value={this.state.header}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                ></Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="12">
                                            <div className="title">
                                                <h4>Description</h4>
                                            </div>
                                            <Input
                                                rows="4"
                                                cols="80"
                                                name="description"
                                                type="textarea"
                                                value={this.state.description}
                                                placeholder="Write your large description here..."
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                            ></Input>
                                        </Col>
                                        <Col md="12">
                                            <div className="title">
                                                <h4>Tags</h4>
                                            </div>
                                            <TagsInput
                                                className="pull-left"
                                                tagProps={{
                                                    className: 'react-tagsinput-tag badge badge-success',
                                                }}
                                                onChange={(value) => this.setState({ tags: value })}
                                                value={this.state.tags}
                                                onlyUnique
                                            ></TagsInput>
                                        </Col>
                                        <Col md="12">
                                            <hr></hr>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.is_public}
                                                        onChange={(e) => {
                                                            this.state.is_public = !this.state.is_public

                                                            if (!this.state.is_public && this.state.allow_public_upload) this.state.allow_public_upload = false

                                                            this.setState({
                                                                is_public: this.state.is_public,
                                                                allow_public_upload: this.state.allow_public_upload,
                                                            })
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    {this.state.is_public ? 'Visible to everyone' : 'Visible to everyone'}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.is_unlisted}
                                                        onChange={(e) => {
                                                            this.state.is_unlisted = !this.state.is_unlisted
                                                            if (this.state.is_unlisted) {
                                                                this.state.is_public = true
                                                            } else {
                                                                if (this.props.gallery && !this.props.gallery.is_public && !this.props.gallery.is_unlisted) {
                                                                    this.state.is_public = false
                                                                }
                                                            }

                                                            this.setState({
                                                                is_unlisted: this.state.is_unlisted,
                                                                is_public: this.state.is_public,
                                                            })
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    {this.state.is_unlisted ? 'Unlisted. Anyone with a link can see.' : 'Unlisted. Gallery will be public.'}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup check disabled={!this.state.is_public}>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={this.state.allow_public_upload}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                allow_public_upload: !this.state.allow_public_upload,
                                                            })
                                                        }}
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                    Allow Public Uploads
                                                </Label>
                                            </FormGroup>
                                        </Col>

                                        <Col md="12"><br></br></Col>
                                        <Col md="12 div-bottom">
                                            <div className="title"></div>
                                            <Button className="btn-round" color="info" href="#" onClick={this.submitForm} size="lg">
                                                {!this.state.id ? 'Launch Gallery' : 'Save Gallery '}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default GalleryForm
