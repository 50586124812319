import React from 'react'

// reactstrap components
import { Button, Card, CardBody, CardFooter, CardTitle, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from 'reactstrap'

// core components
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar.js'
import Footer from 'components/Footers/Footer.js'

import SignupForm from 'components/Forms/SignUpForm.js'

function SignupPage() {
    const [firstFocus, setFirstFocus] = React.useState(false)
    const [lastFocus, setLastFocus] = React.useState(false)
    const [emailFocus, setEmailFocus] = React.useState(false)
    React.useEffect(() => {
        document.body.classList.add('signup-page')
        document.body.classList.add('sidebar-collapse')
        document.documentElement.classList.remove('nav-open')
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
        return function cleanup() {
            document.body.classList.remove('signup-page')
            document.body.classList.remove('sidebar-collapse')
        }
    }, [])
    return (
        <>
            <FixedTransparentNavbar />
            <div className="page-header header-filter" filter-color="black">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: 'url(' + require('assets/img/login.jpg').default + ')',
                    }}
                ></div>
                <div className="content">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="6" lg="4">
                                <div className="info info-horizontal">
                                    <div className="icon icon-info">
                                        <i className="now-ui-icons media-2_sound-wave"></i>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Upload your Images</h5>
                                        <p className="description">Edit and organize your images</p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="icon icon-info">
                                        <i className="now-ui-icons media-1_button-pause"></i>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Share your Images and Videos</h5>
                                        <p className="description">Share with your family and friends</p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="icon icon-info">
                                        <i className="now-ui-icons users_single-02"></i>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Built Audience</h5>
                                        <p className="description">Get people to follow your work</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="mr-auto" md="6" lg="4">
                                <SignupForm />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default SignupPage
