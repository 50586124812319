import React from 'react'

import { controller } from 'controller.js'

class Notifications extends React.Component {
    componentDidMount() {
        controller.register('alert', this)
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    setText(highlight, text, className, icon) {
        this.setState({
            notification_text: text,
            notification_highlight: highlight,
            className: className,
            icon: icon,
        })
    }

    setDanger(highlight, text) {
        this.setText(highlight, text, 'alert-danger', 'objects_support-17')
    }

    setSuccess(highlight, text) {
        this.setText(highlight, text, 'alert-success', 'ui-2_like')
    }

    setInfo(highlight, text) {
        this.setText(highlight, text, 'alert-info', 'travel_info')
    }

    setWarning(highlight, text) {
        this.setText(highlight, text, 'alert-warning', 'ui-1_bell-53')
    }

    render() {
        if (!this.state.className) return ""

        return (
            <>
                <div className="section section-notifications">
                    <div className={'alert ' + this.state.className} role="alert">
                        <div className="container">
                            <div className="alert-icon">
                                <i className={'now-ui-icons ' + this.state.icon}></i>
                            </div>
                            <strong>{this.state.notification_highlight}</strong> {this.state.notification_text}
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                                onClick={(e) => {
                                    console.log('Close component ')
                                    this.setState({
                                        className: null,
                                    })
                                }}
                            >
                                <span aria-hidden="true">
                                    <i className="now-ui-icons ui-1_simple-remove"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Notifications
