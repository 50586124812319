import React from 'react'

import { Badge, Button, Card, CardBody, CardFooter, CardTitle, NavItem, NavLink, Nav, TabContent, TabPane, Container, Row, Col } from 'reactstrap'

import { toast } from 'react-toastify'
import { controller } from 'controller.js'
import { getApiPath } from 'config.js'

class GalleryModalItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            galleries: {},
        }
    }

    componentDidMount() {
        debugger
        console.log(" Load Item " + this.props.gallery_id)
        controller.get_gallery_by_id(this, this.props.gallery_id)
    }

    render() {
        if (!this.state.gallery)
            return (
                <div>
                    <br></br>
                </div>
            )

        let image = require('assets/img/pricing1.jpg').default

        if (this.state.gallery.cover_id)
            image = getApiPath() + `/media/get_image/` + this.state.gallery.cover_id

        let gallery = this.state.gallery
        if (!gallery) return ''

        let btnClasses = 'btn-icon btn-round btn btn-info'
        return (
            <>
                <div className="instruction">
                    <Row>
                        <Col md="2">
                            <button
                                type="button"
                                className={this.state.gallery.added ? btnClasses + ' disabled' : btnClasses}
                                onClick={(e) => {
                                    e.preventDefault()
                                    controller.media_attach(this.props.media, gallery.id, () => {
                                        this.state.gallery.added = true;
                                        this.forceUpdate()
                                        toast.success('Added')
                                    })
                                }}
                            >
                                <i className="now-ui-icons ui-1_simple-add"></i>
                            </button>
                        </Col>
                        <Col md="6">
                            <strong style={{ textTransform: 'capitalize' }}>
                                <a href={'/user/' + gallery.username + '/' + gallery.id}>
                                    <b>{gallery.title}</b>
                                </a>
                            </strong>
                            <p className="description">{ gallery.description == gallery.title ? "" :  gallery.description}</p>
                        </Col>
                        <Col md="4">
                            <div className="picture">
                                <img alt="..." className="rounded img-raised" src={image}></img>
                            </div>
                        </Col>
                    </Row>
                    <br></br>
                </div>
            </>
        )
    }
}

export default GalleryModalItem
