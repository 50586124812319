import React from 'react'

import {
    Button,
    CardTitle,
    Card,
    CardFooter,
    CardBody,
    Collapse,
    FormGroup,
    Form,
    Input,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from 'reactstrap'

import GalleryView from 'components/GalleryView.js'
import { controller } from 'controller.js'

class GalleryHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gallery: null,
        }
        controller.get_user(this, props.username)
        controller.get_gallery(this, props.username, props.gallery_name)
    }

    render() {
        if (!this.state.user) return 'Loading...'

        if (!this.state.gallery) {
            return <div>...Not loaded...</div>
        }

        let image = require('assets/img/pricing1.jpg').default
        let gallery = this.state.gallery
        if (!gallery) return ''

        let btnClasses = 'btn-icon btn-round btn btn-info'
        return (
            <>
                <Container fluid style={{ height: "200px" }}>
                    <Row>
                        <Col className="px-0" md="6">
                            <Card
                                className="card-fashion card-background"
                                style={{
                                    backgroundImage: 'url(' + require('assets/img/bg26.jpg').default + ')',
                                }}
                            >
                                <CardBody>
                                    <CardTitle className="text-left" tag="div">
                                        <h2>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                {gallery.title}
                                            </a>
                                        </h2>
                                    </CardTitle>
                                    <CardFooter className="text-left">
                                        <div className="stats">
                                            <span>
                                                <i className="now-ui-icons users_circle-08"></i>
                                                Author
                                            </span>
                                            <span>
                                                <i className="now-ui-icons tech_watch-time"></i>
                                                June 2, 2021
                                            </span>
                                        </div>
                                        <div className="stats-link pull-right">
                                            <a className="footer-link" href="#pablo" onClick={(e) => e.preventDefault()}>
                                                People
                                            </a>
                                        </div>
                                    </CardFooter>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="px-0" md="6">
                            <div className="card-container">
                                <Card className="card-fashion">
                                    <CardTitle tag="h4">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            {gallery.description}
                                        </a>
                                    </CardTitle>
                                    <CardBody>
                                        <CardFooter>
                                            <div className="stats">
                                                <span>
                                                    <i className="now-ui-icons users_circle-08"></i>
                                                    Author
                                                </span>
                                                <span>
                                                    <i className="now-ui-icons tech_watch-time"></i>
                                                    June 2, 2021
                                                </span>
                                            </div>
                                            <div className="stats-link pull-right">
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    Mountains
                                                </a>
                                                ‚{' '}
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    Outdoor
                                                </a>
                                            </div>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                                <Card
                                    className="card-fashion card-background"
                                    style={{
                                        backgroundImage: "url('http://mvpwp.com/demo/nowwp/wp-content/uploads/sites/6/2017/06/a-james-donovan-180375.jpg')",
                                    }}
                                ></Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default GalleryHeader
