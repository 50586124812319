import React from 'react'

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Col, Row } from 'reactstrap'

// core components
import DropdownScrollNavbar from 'components/Navbars/DropdownScrollNavbar.js'
import LoginForm from 'components/Forms/LoginForm.js'

import Footer from 'components/Footers/Footer.js'

const renderErrorMessage = (name) => name === errorMessages.name && <div className="error">{errorMessages.message}</div>

function LoginPage() {
    const [firstFocus, setFirstFocus] = React.useState(false)
    const [lastFocus, setLastFocus] = React.useState(false)
    React.useEffect(() => {
        document.body.classList.add('login-page')
        document.body.classList.add('sidebar-collapse')
        document.documentElement.classList.remove('nav-open')
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
        return function cleanup() {
            document.body.classList.remove('login-page')
            document.body.classList.remove('sidebar-collapse')
        }
    }, [])

    return (
        <>
            <DropdownScrollNavbar />
            <div className="page-header header-filter" filter-color="black">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: 'url(' + require('assets/img/bg18.jpg').default + ')',
                    }}
                ></div>

                <div className="content">
                    <div className="section section-notifications">
                        <div id="alert" className="alert alert-danger hidden" role="alert">
                            <div className="container">
                                <div className="alert-icon">
                                    <i className="now-ui-icons objects_support-17"></i>
                                </div>
                                <strong>Oh snap!</strong> Change a few things up and try submitting again.
                                <button type="button" className="close">
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <Container>
                        <Row>
                            <div className="text-center full-width">
                                <div className="logo-container">
                                    <img alt="..." src={require('assets/img/img-api-logo-prealpha.png').default}></img>
                                </div>
                            </div>
                            <LoginForm />

                            <Col className="ml-auto mr-auto" md="5">
                                <Card className="card-login card-plain">
                                    <div className="pull-left">
                                        <h6>
                                            <a
                                                className="link footer-link"
                                                href="/sign-up"
                                                onClick={(e) => {
                                                    console.log(' Sign up redirect ')
                                                }}
                                            >
                                                Create Account
                                            </a>
                                        </h6>
                                    </div>
                                    <div className="pull-right">
                                        <h6>
                                            <a
                                                className="link footer-link"
                                                href="/contact-us"
                                                onClick={(e) => {
                                                    console.log(' Contact us ')
                                                }}
                                            >
                                                Need Help?
                                            </a>
                                        </h6>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default LoginPage
