import React from 'react'

import { Button, Card, CardBody, Collapse, FormGroup, Form, Input, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col, Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'

// core components
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar.js'
import GalleriesList from 'views/GalleriesList.js'
import Footer from 'components/Footers/FooterBlack.js'

function UserGalleryPage() {
    React.useEffect(() => {
        document.body.classList.add('gallery-page')
        document.body.classList.add('sidebar-collapse')
        document.documentElement.classList.remove('nav-open')
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
        return function cleanup() {
            document.body.classList.remove('gallery-page')
            document.body.classList.remove('sidebar-collapse')
        }
    }, [])

    var arr = window.location.pathname.split('/')
    var username = arr.length > 2 ? arr[2] : undefined

    return (
        <>
            <FixedTransparentNavbar />
            <div className="wrapper">
                <GalleriesList username={username} />
                <Footer />
            </div>
        </>
    )
}

export default UserGalleryPage
