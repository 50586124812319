import React from 'react'

import { getApiPath } from 'config.js'

const renderErrorMessage = (name) => name === errorMessages.name && <div className="error">{errorMessages.message}</div>

function LogoutPage() {
    fetch(getApiPath() + '/user/logout', {
        method: 'get',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            window.location.href = "/"
        })
        .catch((error) => {
            window.location.href = "/"
            alert('INTERNAL SERVER ERROR ' + error)
        })

    return (
        <h1>Logging out...</h1>
    )
}

export default LogoutPage
