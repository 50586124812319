/*eslint-disable*/
import React from 'react'

// reactstrap components
import { Container } from 'reactstrap'

// core components

function FooterDefault() {
    return (
        <>
            <footer className="footer footer-default">
                <Container>
                    <nav>
                        <ul>
                            <li>
                                <a href="https://www.engineer.blue" target="_blank">
                                    Engineer.blue
                                </a>
                            </li>
                            <li>
                                <a href="/about-us" target="_blank">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a href="/blog-posts" target="_blank">
                                    Blog
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="copyright" id="copyright">
                        © {new Date().getFullYear()}, Img-API All Rights reserved
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default FooterDefault
