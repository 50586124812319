/*eslint-disable*/
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, Container, UncontrolledTooltip } from 'reactstrap'
import { getApiPath } from 'config.js'

import UserNavBar from './UserNavBar'
import UserNavDropDown from './UserNavDropDown'

import { ToastContainer, toast } from 'react-toastify'

function GetNavBar() {
    // https://fkhadra.github.io/react-toastify/introduction
    const navBar = (
        <>
            <ToastContainer
                hideProgressBar={true}
                theme="colored"
                pauseOnFocusLoss={false}
                autoClose={2000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                position="bottom-right"
            />

            <Nav className="ml-auto" id="ceva" navbar>
                <UserNavDropDown />
                <UncontrolledDropdown nav>
                    <DropdownToggle caret color="default" data-toggle="dropdown" href="#sergio" id="navbarDropdownMenuLink" nav onClick={(e) => e.preventDefault()}>
                        <i aria-hidden={true} className="now-ui-icons design_image"></i>
                        <p>Explore</p>
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                        <DropdownItem to="/trending" tag={Link}>
                            <i className="fal fa-star fa-lg"></i>
                            Trending
                        </DropdownItem>
                        <DropdownItem to="/public_galleries" tag={Link}>
                            <i className="fal fa-images fa-lg"></i>
                            Public Galleries
                        </DropdownItem>
                        <DropdownItem disabled to="/sections#blogs" tag={Link}>
                            <i className="now-ui-icons text_align-left"></i>
                            Blogs
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav>
                    <DropdownToggle caret color="default" data-toggle="dropdown" href="#sergio" id="navbarDropdownMenuLink_2" nav onClick={(e) => e.preventDefault()}>
                        <i aria-hidden={true} className="now-ui-icons design_image"></i>
                        <p>Us</p>
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink_2" right>
                        <DropdownItem disabled tag={Link} to="/blog-post">
                            <i className="now-ui-icons text_align-left"></i>
                            Blog Post
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/about-us">
                            <i className="now-ui-icons business_bulb-63"></i>
                            About-us
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/contact-us">
                            <i className="now-ui-icons location_pin"></i>
                            Contact Us
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UserNavBar />
            </Nav>
        </>
    )

    return navBar
}

export default GetNavBar

/*

                        <DropdownItem tag={Link} to="/login-page">
                            <i className="now-ui-icons users_circle-08"></i>
                            Login Page
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/sign-up">
                            <i className="now-ui-icons tech_mobile"></i>
                            Signup Page
                        </DropdownItem>

*/
