import { getApiPath } from 'config.js'
import { toast } from 'react-toastify'
import { createRef } from 'react'

// Global controller, we register things which are common for the entire site here
class Controller {
    constructor() {
        this.state = {
            isLoaded: false,
            users: {},
            cache: {},
            loaders: {},
            target_gallery_upload: '',
        }
        this.services = {}
        this.get_user(this, 'me')
    }

    /******** Global services **************/

    forceUpdate() {
        console.log('Do nothing')
    }

    register(name, obj) {
        this.services[name] = obj
    }

    get(name) {
        return this.services[name]
    }

    /******** API & Backend **************/
    fetch_token() {
        fetch(getApiPath() + '/user/token', this.get_headers())
            .then((response) => response.json())
            .then((data) => {
                console.log('TOKEN ' + data.token)
                if (data.status != 'success') {
                    console.log(data.error_msg)
                    return
                }
                console.log('USERNAME ' + data.username)
            })
            .catch((error) => {
                console.log('INTERNAL SERVER ERROR ' + error)
            })
    }

    testCORS() {
        var req = new XMLHttpRequest()
        var url = getApiPath() + '/user/token?Request_XML'

        req.open('GET', url, true)
        req.withCredentials = true
        req.onload = () => {
            var jsonResponse = JSON.parse(req.responseText)
            console.log('TOKEN: ' + jsonResponse.token)
        }
        req.send()
    }

    get_headers(method = 'get', data = null) {
        let ret = {
            method: method,
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }

        if (method == 'post' && data) {
            ret.body = JSON.stringify(data)
        }

        return ret
    }

    upload_files(acceptedFiles, result, reject) {
        var data = new FormData()
        let count = 0
        for (let input of acceptedFiles) {
            data.append('file_' + count, input, input.name)
            count += 1
        }
        let api_url = getApiPath() + '/media/upload'

        if (this.state.target_gallery_upload) {
            api_url += '?gallery_id=' + this.state.target_gallery_upload
        }

        fetch(api_url, {
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: data,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    if (reject) return reject(data.error_msg)
                }

                return result(data)
            })
            .catch((error) => {
                if (reject) return reject(error)
                this.get('alert').setDanger('Error', 'There was a problem accessing this server ' + error)
            })
    }

    api_call(api_url, result, reject) {
        // Our simplified Backend data call, result or reject.
        // We might reject due an error on an internal error
        fetch(api_url, this.get_headers())
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    if (reject) return reject(data.error_msg)

                    toast.error(data.error_msg)
                    return
                }

                return result(data)
            })
            .catch((error) => {
                if (reject) return reject(error)
                debugger
                this.get('alert').setDanger('Error', 'There was a problem accessing this server ' + error)
            })
    }

    /************* Gallery ***************/

    get_user(container, username = 'me') {
        if (this.state.users[username]) {
            container.state.isLoaded = true
            container.state.user = this.state.users[username]
            return this.state.users[username]
        }

        // We create a queue of containers waiting for this loader to finish
        if (!this.state.loaders[username]) {
            console.log('+++++++++ User Query ++++++++ ' + username)
            this.state.loaders[username] = [container]
        } else {
            console.log('+++++ Cache User Query ++++++ ' + username)

            var f = this.state.loaders[username].find((element) => {
                if (element == container) {
                    return true
                }

                return false
            })

            if (f) {
                console.log(' Already waiting to get results ')
                return
            }

            this.state.loaders[username].push(container)
            return
        }

        if (!username) {
            username = 'me'
        }

        return new Promise((resolve) => {
            let api_url = getApiPath() + '/user/get/' + username

            this.api_call(
                api_url,
                (data) => {
                    this.state.users[username] = data.user
                    console.log('==================== UPDATE USER ====================' + container)

                    for (let container of this.state.loaders[username]) {
                        container.state.isLoaded = true
                        container.state.user = data.user
                        container.forceUpdate()
                    }

                    this.state.loaders[username] = []
                },
                (error) => {
                    console.log('============== FINISHED LOADING - ERROR ============== ' + error)
                    for (let container of this.state.loaders[username]) {
                        container.state.isLoaded = true
                        container.state.user = null
                        container.forceUpdate()
                    }

                    this.state.loaders[username] = []
                }
            )
        })
    }

    get_section(container, section) {
        return new Promise((resolve) => {
            let api_url = getApiPath() + '/content/' + section + '/get'

            this.api_call(
                api_url,
                (data) => {
                    console.log('==================== UPDATE CLIENT ====================' + container)
                    container.state[section] = data[section]
                    container.state.is_loaded = true
                    container.forceUpdate()
                },
                (error) => {
                    debugger
                    console.log('============== FINISHED LOADING - ERROR ============== ' + error)
                    this.get('alert').setDanger('Error', 'There was a problem accessing this server ' + error)
                }
            )
        })
    }

    get_current_username() {
        if (!this.state.users) {
            return ''
        }

        if (this.state.users.me) return this.state.users.me.username

        return ''
    }

    is_current_media_user(component) {
        let username = this.get_current_username()
        if (!username) return false
        if (username == "admin")
            return true

        return component.state.media.username == username
    }

    is_current_user(component) {
        let username = this.get_current_username()
        if (!username) return false

        if (username == "admin")
            return true

        if (!component.state.user) return false

        return component.state.user.username == username
    }

    save_json_update(api_entry, data, result, reject) {
        let api_url = getApiPath() + api_entry
        let headers = this.get_headers('post', data)
        fetch(api_url, headers)
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    if (reject) return reject(data.error_msg)
                }

                if (result) return result(data)
            })
            .catch((error) => {
                if (reject) return reject(error)
                this.get('alert').setDanger('Error', 'There was a problem accessing this server ' + error)
            })
    }

    save_update(api_entry, value, result, reject) {
        return this.save_json_update(api_entry, { value: value }, result, reject)
    }

    save_section_update(section, key, value, result, reject) {
        let api_entry = '/content/' + section + '/update'
        return this.save_json_update(api_entry, { [key]: value }, result, reject)
    }

    /************* Media ***************/

    media_update(media_id, data, result, reject) {
        data[media_id] = media_id
        let api_url = getApiPath() + '/media/update'

        fetch(api_url, this.get_headers('post', data))
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    controller.get('alert').setDanger('Error', data.error_msg)
                    return
                }

                if (result) return result(data)
            })
            .catch((error) => {
                debugger
                controller.get('alert').setDanger('Error', 'There was a problem accessing this server')
                console.log(error)
            })
    }

    media_toggle(media, what, result, reject) {
        let api_url = getApiPath() + '/user/media/' + media.media_id + '/toggle/' + what
        this.api_call(api_url, result, reject)
    }

    media_set(media, what, result, reject) {
        let api_url = getApiPath() + '/user/media/' + media.media_id + '/append/' + what
        this.api_call(api_url, result, reject)
    }

    media_set_cover(media, what, result, reject) {
        let api_url = getApiPath() + '/user/media/' + media.media_id + '/set_cover/' + what
        this.api_call(api_url, result, reject)
    }

    media_set_background(media, what, result, reject) {
        let api_url = getApiPath() + '/user/media/' + media.media_id + '/set_background/' + what
        this.api_call(api_url, result, reject)
    }

    media_attach(media, where, result, reject) {
        this.media_set(media, where, result, reject)
    }

    media_unset(media, what, result, reject) {
        let api_url = getApiPath() + '/user/media/' + media.media_id + '/remove/' + what
        this.api_call(api_url, result, reject)
    }

    media_detach(media, where, result, reject) {
        this.media_unset(media, where, result, reject)
    }

    media_remove(media, result, reject) {
        let api_url = getApiPath() + '/media/remove/' + media.media_id
        this.api_call(api_url, result, reject)
    }

    /************* Gallery ***************/

    get_route_apicall(username) {
        var api_call = ''

        if (username) api_call = '/media/stream/' + username
        else api_call = '/media/stream'

        var arr = window.location.pathname.split('/')

        if (!username) username = ['me'].includes(arr[1]) ? arr[1] : arr[2]

        let gallery_id = arr.length >= 4 ? arr[3] : null
        let post_id = arr.length >= 5 ? arr[4] : null

        if (gallery_id) api_call = '/user/' + username + '/list/' + gallery_id + '/get'
        if (post_id) api_call = '/media/posts/' + post_id + '/get'

        if (api_call == '') {
            console.log('++++++++++ Not ready yet ++++++++++')
        }

        if (arr.length >= 7) gallery_id = arr[6]

        return {
            username: username,
            gallery_id: gallery_id,
            post_id: post_id,
            api_call: api_call,
        }
    }

    get_gallery_call(container, api_call, extra_params, callback) {
        /* Not used yet */

        console.log('==================== START API CALL =======================')
        if (this.state.cache[api_call]) {
            let obj = this.state.cache[api_call]
            container.state.isLoaded = true
            container.state.imageSource = obj.imageSource
            container.state.gallery = obj.gallery
            container.state.is_empty = obj.is_empty
            container.forceUpdate()
            return
        }

        return fetch(api_call, controller.get_headers())
            .then((response) => response.json())
            .then((data) => {
                console.log('==================== FETCHED =======================')
                var obj = {
                    is_loaded: true,
                    imageSource: [],
                }

                container.setState(obj)

                if (data.status != 'success') {
                    controller.get('alert').setDanger('Error', data.error_msg)
                    return data
                }

                if (data.media_files.length == 0) {
                    clearInterval(this.adjust_interval)
                    obj = {
                        gallery: data,
                        is_empty: true,
                    }

                    this.state.cache[api_call] = obj
                    container.setState(obj)
                    return
                }

                let fileList = data.media_files

                obj = {
                    is_empty: false,
                    gallery: data,
                    imageSource: fileList,
                }

                container.setState(obj)

                if (extra_params) {
                    const state = { extra: extra_params }
                    const url = new URL(window.location)

                    let arr = url.pathname.split('/')
                    arr[4] = fileList[0].media_id
                    let new_url = arr.join('/')
                    //window.history.pushState(state, 'Img-API', new_url);
                    window.location.href = new_url
                }

                this.adjust_interval = setInterval(callback, 50)

                return data
            })
            .catch((error) => {
                debugger
                controller.get('alert').setDanger('Error', 'There was a problem accessing this server')
                console.log(error)
            })
    }

    get_galleries(container, username = 'me') {
        return new Promise((resolve) => {
            let api_url = getApiPath() + '/user/' + username + '/list/get'

            this.api_call(api_url, (data) => {
                container.state.galleries = data.galleries
                if (data.current_user == username) {
                    let count = Object.keys(data.galleries).length
                    if (count == 0) container.state.no_galleries = true
                }

                container.forceUpdate()
            })
        })
    }

    get_galleries_call(container, api_call) {
        return new Promise((resolve) => {
            let api_url = getApiPath() + api_call

            this.api_call(api_url, (data) => {
                container.state.galleries = data.galleries
                container.forceUpdate()
            })
        })
    }

    get_gallery(container, username, gallery_id) {
        if (!gallery_id) return

        return new Promise((resolve) => {
            let api_url = getApiPath() + '/user/' + username + '/list/' + gallery_id + '/get'
            this.api_call(api_url, (data) => {
                container.state.gallery = data
                container.forceUpdate()
            })
        })
    }

    get_gallery_by_id(container, gallery_id) {
        return new Promise((resolve) => {
            let api_url = getApiPath() + '/user/list/get_by_id/' + gallery_id + '?populate=1'

            this.api_call(api_url, (data) => {
                container.state.gallery_id = gallery_id
                if (data.list_type == 'favs') data.title = 'Favourites'

                container.state.gallery = data
                container.forceUpdate()
            })
        })
    }

    gallery_remove(gallery_id, result, reject) {
        let api_url = getApiPath() + '/user/me/list/' + gallery_id + '/remove'
        this.api_call(api_url, result, reject)
    }

    gallery_get_image(image_type, container, state_name, gallery_id, result, reject) {
        return new Promise((resolve) => {
            let api_url = getApiPath() + '/user/' + container.props.username + '/list/' + gallery_id + '/get/' + image_type
            this.api_call(
                api_url,
                (data) => {
                    console.log('------------ Loaded ' + api_url + ' ----------')
                    if (data.media_list.length > 0) {
                        cover_
                        let media = data.media_list[0]
                        let media_id = media.media_id

                        container.setState({ [state_name]: media_id })
                    }
                },
                (error) => {
                    console.log(' Do nothing ')
                }
            )
        })
    }

    gallery_set(gallery_id, param, value, result, reject) {
        let api_url = getApiPath() + '/user/me/list/' + gallery_id + '/set/' + param + '/' + value
        this.api_call(api_url, result, reject)
    }

    /************* Dropzone ***************/

    set_dropzone_target_gallery(gallery_id) {
        if (!gallery_id || gallery_id == 'undefined') {
            console.log('Dropzone target is empty')
            this.state.target_gallery_upload = ''
            return
        }

        console.log('############### TARGET ' + gallery_id + ' ######################')
        this.state.target_gallery_upload = gallery_id
    }

    open_uploading_dialog() {
        if (!this.dropzoneRef) {
            this.get('alert').setDanger('Error', 'Dropzone is not available on this view')
            return
        }

        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open()
            return
        }

        this.get('alert').setDanger('Error', 'Dropzone is not ready')
    }

    get_dropzone_ref() {
        if (this.dropzoneRef) return this.dropzoneRef

        this.dropzoneRef = createRef()
        return this.dropzoneRef
    }
}

export const controller = new Controller()
