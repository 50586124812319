import React from 'react'

import { Button, Card, CardBody, Collapse, FormGroup, Form, Input, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col, Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'

import { controller } from 'controller.js'
import { toast } from 'react-toastify'
import GalleryForm from 'components/Forms/GalleryForm.js'

class GalleryNavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: props.username,
        }

        if (['undefined', 'null', 'posts', 'stream'].includes(props.gallery_id)) return

        if (props.username && props.gallery_id) controller.get_gallery(this, props.username, props.gallery_id)
    }

    render() {
        if (!this.state.gallery) return ''
        return (
            <>
                <Navbar className="navbar-gallery" expand="lg">
                    <Nav className="pull-right mx-auto" navbar>
                        {this.state.gallery.is_order_asc ? (
                            <NavItem className="">
                                <NavLink
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        controller.gallery_set(this.state.gallery.id, 'is_order_asc', false, (data) => {
                                            controller.get('gallery').reload()
                                            this.state.gallery.is_order_asc = false
                                            this.forceUpdate()
                                        })
                                    }}
                                >
                                    <i className="fal fa-arrow-up fa-lg"></i> Show OLDEST First
                                </NavLink>
                            </NavItem>
                        ) : (
                            <NavItem className="">
                                <NavLink
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        controller.gallery_set(this.state.gallery.id, 'is_order_asc', true, (data) => {
                                            controller.get('gallery').reload()
                                            this.state.gallery.is_order_asc = true
                                            this.forceUpdate()
                                        })
                                    }}
                                >
                                    <i className="fal fa-arrow-down fa-lg"></i> Show NEWEST First
                                </NavLink>
                            </NavItem>
                        )}

                        <NavItem className="">
                            <NavLink
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    let modal = controller.get('modal_large')
                                    modal.show(<GalleryForm gallery={this.state.gallery} callback={this.gallery_edited} />)
                                    modal.setModal(true)
                                }}
                            >
                                <i className="fal fa-edit fa-lg"></i> EDIT GALLERY
                            </NavLink>
                        </NavItem>

                        {this.state.gallery.is_public ? (
                            <NavItem className={''}>
                                <NavLink
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        controller.gallery_set(this.state.gallery.id, 'is_public', false, (data) => {
                                            this.setState({
                                                gallery: data.galleries[0],
                                            })

                                            if (!this.state.gallery.is_public) toast.success('Gallery is now Private')
                                            else toast.error('Failed setting, enough permissions?')

                                            controller.get('gallery').reload()
                                        })
                                    }}
                                >
                                    <i className="fal fa-lock fa-lg"></i> SET GALLERY PRIVATE
                                </NavLink>
                            </NavItem>
                        ) : (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        controller.gallery_set(this.state.gallery.id, 'is_public', true, (data) => {
                                            this.setState({
                                                gallery: data.galleries[0],
                                            })

                                            if (this.state.gallery.is_public) toast.success('Gallery is now Public')
                                            else toast.error('Failed setting, enough permissions?')

                                            controller.get('gallery').reload()
                                        })
                                    }}
                                >
                                    <i className="fal fa-lock-open fa-lg"></i> SET GALLERY PUBLIC
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </Navbar>
            </>
        )
    }
}

export default GalleryNavBar
