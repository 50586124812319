import React from 'react'

// reactstrap components

// core components
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar.js'
import Footer from 'components/Footers/FooterBlack.js'

import TopImages from './sections-sections/TopImages.js'
import UploadHeader from 'components/Headers/UploadHeader.js'

import { ToastContainer, toast } from 'react-toastify'

function ImgAPI() {
    React.useEffect(() => {
        document.body.classList.add('signup-page')
        document.body.classList.add('sidebar-collapse')
        document.documentElement.classList.remove('nav-open')
        var href = window.location.href.substring(window.location.href.lastIndexOf('#/') + 2)
        var hrefId = href.substring(href.lastIndexOf('#') + 1)
        if (href.lastIndexOf('#') > 0) {
            document.getElementById(hrefId).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        }
        return function cleanup() {
            document.body.classList.remove('signup-page')
            document.body.classList.remove('sidebar-collapse')
        }
    })

    var ret = (
        <>
            <div className="main-wrapper">
                <FixedTransparentNavbar />
                <UploadHeader target_gallery_id="stream" />

                <div
                    className="wrapper"
                    style={{
                        minHeight: 1024,
                        backgroundColor: '#000000',
                    }}
                >
                    <TopImages api_call="/media/category/NEW" show_username={true} />
                </div>

                <div className="section-space"></div>
            </div>
            <Footer />
        </>
    )
    return ret
}

export default ImgAPI
