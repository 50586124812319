import React from 'react'

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Col, Row } from 'reactstrap'
import { getUrlParameter } from 'utils.js'
import { getApiPath } from 'config.js'

import { toast } from 'react-toastify'
import { controller } from 'controller.js'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailError: false,
            passwordError: false,
            isDisabled: true,
        }

        this.state.email = getUrlParameter('email')
        this.submitForm = this.submitForm.bind(this)
    }

    validateEmail(email) {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g
        const result = pattern.test(email)
        if (result === true) {
            this.setState({
                emailError: false,
                email: email,
            })
        } else {
            this.setState({
                emailError: true,
            })
        }
    }

    handleChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value,
        })

        if (e.target.name === 'email') {
            //this.validateEmail(e.target.value)
        }

        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    passwordError: true,
                })
            } else {
                this.setState({
                    passwordError: false,
                    password: e.target.value,
                })
            }
        }

        if (this.state.emailError === false && this.state.passwordError === false) {
            this.setState({
                isDisabled: false,
            })
        }
    }
    componentDidMount() {
        if (this.state.email) this.nameInput.focus()
    }
    submitForm(e) {
        e.preventDefault()
        const data = {
            email: this.state.email,
            password: this.state.password,
        }

        this.state.timeout = setTimeout(() => {
            toast.success('Authenticating!', {
                position: 'bottom-right',
                autoClose: 1000,
            })
        }, 500)

        fetch(getApiPath() + '/user/login', {
            method: 'post',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                // Clear the message timeout
                clearTimeout(this.state.timeout)

                if (data.status != 'success') {
                    controller.get('alert').setDanger('Error', data.error_msg)
                    return
                }

                toast.success('Ready!')
                setTimeout(() => {
                    window.location.href = '/me'
                }, 1200)
            })
            .catch((error) => {
                debugger;
                controller.get('alert').setDanger('Error', 'There was a problem accessing this server')
                console.log(error)
            })
    }
    render() {
        const notify = () => {
            var notifications = controller.get('alert')
            notifications.setSuccess('Test', 'test')
        }

        return (
            <div className="container">
                <button className="hidden" onClick={notify}>
                    Notify!
                </button>

                <div className="card card-login card-plain mx-auto">
                    <div className="card-body">
                        <form id="signup-form">
                            <div className="form-group">
                                <div className="form-label-group">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={this.state.email}
                                        className="form-control"
                                        placeholder="Enter your email or username"
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}
                                    />

                                    {this.state.emailError ? <span style={{ color: 'red' }}>Please Enter valid email address</span> : ''}
                                </div>
                            </div>
                            <br></br>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Password"
                                        ref={(input) => {
                                            this.nameInput = input
                                        }}
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}
                                    />

                                    {this.state.passwordError ? <span style={{ color: 'red' }}>Please enter your password</span> : ''}
                                </div>
                            </div>
                            <br></br>
                            <button className="btn btn-info btn-block btn-round no-margin" disabled={this.state.isDisabled} onClick={this.submitForm}>
                                Login
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default LoginForm
