import React from 'react'

import { Modal, ModalFooter, Button, Card, CardTitle, CardBody, CardFooter, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Col, Row } from 'reactstrap'

import { controller } from 'controller.js'

class Modals extends React.Component {
    componentDidMount() {
        controller.register('modals', this)
        this.setModal = this.setModal.bind(this)
        this.show = this.show.bind(this)
        this.reload = this.reload.bind(this)
    }

    constructor(props) {
        super(props)
        this.state = {}
        this.state.content = []
    }

    show(title, content, btn_close) {
        this.setState({
            title: title,
            content: content,
            btn_close: btn_close,
        })
    }

    reload() {
        debugger;
        this.forceUpdate()
    }

    setModal(value) {
        console.log(' Set modal ' + value)
        if (value) {
        } else {
            this.setState({ content: [] })
        }

        this.setState({ modalNotice: value })
    }

    render() {
        return (
            <>
                <div className="hidden" style={{ zIndex: 10000 }}>
                    <Modal
                        isOpen={this.state.modalNotice}
                        toggle={() => {
                            this.setModal(false)
                        }}

                    >
                        <div className="modal-header">
                            <button aria-hidden={false} className="close" onClick={() => this.setModal(false)} type="button">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </button>
                            <h5 className="modal-title" id="myModalLabel">
                                {this.state.title}
                            </h5>
                            <br></br>
                        </div>
                        <div className="modal-body" style={{ overflow: scroll }}>
                            {React.Children.toArray(this.state.content)}
                        </div>
                        <ModalFooter className="justify-content-center">
                            {!this.state.btn_close ? (
                                <Button className="btn-round" color="info" onClick={() => this.setModal(false)}>
                                    Close
                                </Button>
                            ) : (
                                this.state.btn_close
                            )}
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        )
    }
}
export default Modals
