import React from 'react'

// core components
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar.js'
import UserHeader from 'components/Headers/UserHeader.js'
import GalleryHeader from 'components/Headers/GalleryHeader.js'

import Footer from 'components/Footers/FooterBlack.js'
import TopImages from 'views/sections-sections/TopImages.js'
import PostView from 'views/sections-sections/PostView.js'
import UploadHeader from 'components/Headers/UploadHeader.js'
import GalleryNavBar from 'components/Navbars/GalleryNavBar.js'
import UserGalleriesNavBar from 'components/Navbars/UserGalleriesNavBar.js'

import { Row, Col } from 'reactstrap'

import { controller } from 'controller.js'
import { getUrlParameter } from 'utils.js'

class UserPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            api_call: null,
            gallery_id: null,
            value: this.props.value,
        }

        controller.get_user(this, props.username)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value })
        }
    }

    render() {
        if (!this.state.user) return ''

        console.log(' USER PAGE READY ')

        let username = this.state.user ? this.state.user.username : this.props.username

        let api = controller.get_route_apicall(username)
        if (api == null) return ''

        let api_call = api.api_call
        let post_id = api.post_id
        let gallery_id = api.gallery_id

        console.log(' APICALL ' + api_call)

        let user_view =
            gallery_id != 'galleries' ? (
                <div>
                    {gallery_id != 'posts' ? <UserHeader username={api.username} post_id={post_id} gallery_id={gallery_id} /> : ''}

                    <div
                        className="wrapper"
                        style={{
                            paddingTop: 7,
                            backgroundColor: '#000000',
                        }}
                    >
                        {gallery_id != 'posts' && controller.is_current_user(this) ? (
                            <div>
                                <UploadHeader small={true} gallery_id={gallery_id} />
                                <Row>
                                    <Col className="px-0" md="12">
                                        <GalleryNavBar username={username} gallery_id={gallery_id} />
                                    </Col>
                                </Row>
                                <TopImages username={this.props.username} gallery_id={gallery_id} api_call={api_call} enable_full_width={this.props.post_id ? true : false} reverse_order={false} />
                            </div>
                        ) : (
                            <div>
                                <PostView username={this.props.username} gallery_id={gallery_id} api_call={api_call} enable_full_width={this.props.post_id ? true : false} reverse_order={false} />
                            </div>
                        )}

                    </div>
                </div>
            ) : (
                ''
            )

        return (
            <>
                <div className="main-wrapper">
                    <FixedTransparentNavbar />
                    <div
                        className="wrapper"
                        style={{
                            backgroundColor: '#000000',
                        }}
                    >
                        <Row>
                            <Col className="px-0" md="12">
                                <UserGalleriesNavBar username={username} show_galleries_view={false} />
                            </Col>
                        </Row>
                        {user_view}
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default UserPage
